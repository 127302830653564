import React from 'react';

import { Button } from '../BaseButton/Button';

type BaseButtonProps = Omit<
  JSX.IntrinsicElements['button'],
  'color' | 'type'
> & {
  inverted?: boolean;
  hoverIcon?: React.ReactNode;
};

export const TextButton = ({
  onClick,
  children,
  inverted,
  disabled,
  icon,
  hoverIcon,
  ...props
}: BaseButtonProps & { icon?: React.ReactNode }): JSX.Element => (
  <Button
    {...props}
    onClick={onClick}
    type="button"
    disabled={disabled}
    cursor={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
    color={
      inverted
        ? 'text-main hover:text-main-500'
        : 'text-main-500 hover:text-main'
    }
    extraClassNames='group flex flex-row items-center'
  >
    {hoverIcon ? (
      <>
        <span className='block group-hover:hidden'>

          {icon}
        </span>
        <span className='hidden group-hover:block'>
          {hoverIcon}
        </span>
      </>
    ) : (
      icon
    )}
    {children}
  </Button>
);
