import React from 'react';

import type { ButtonProps } from '../types';
import { Text } from '../../Text';

export const Button = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>({
  ...textEngineProps
}: ButtonProps<
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>): JSX.Element => (
  <Text
    data-testid="button-engine"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...textEngineProps}
    as="button"
  />
);
