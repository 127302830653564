import React from 'react';

import { type BaseSvgProps, colorToHexCode } from '../../utils';

export const Trash = ({
  width = 12,
  primaryColorHexCode = colorToHexCode.black,
  'data-testid': testId,
}: BaseSvgProps) => (
  <svg
    data-testid={testId}
    width={width}
    height={width}
    viewBox="0 0 12 12"
    className="shrink-0"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 1.63086V1.12207H6.5V1.63086H9.0625L9.0625 1.63096H9.625V2.62207H9.02213L8.6859 10.8777H2.37268L1.98415 2.62207H1.375L1.375 1.63096H1.9375L1.9375 1.63086H4.5ZM8.11987 2.62991H2.82486L3.20875 9.8787H7.78699L8.11987 2.62991Z"
      fill={primaryColorHexCode}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00429 3.66406L5.00429 8.64551L4.01318 8.64551L4.01318 3.66406L5.00429 3.66406Z"
      fill={primaryColorHexCode}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.98671 3.66406L6.98671 8.64551L5.99561 8.64551L5.99561 3.66406L6.98671 3.66406Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
