import clsx from 'clsx';
import React from 'react';

import { Button } from '../BaseButton/Button';

type TertiaryButtonProps = Omit<
  JSX.IntrinsicElements['button'],
  'color' | 'fontDecoration' | 'background'
>;

export const TertiaryButton: React.FC<TertiaryButtonProps> = ({
  children,
  type = 'button',
  className,
  ...props
}) => (
  <Button
    type={type}
    color="text-main-500 enabled:hover:text-main"
    extraClassNames={clsx(
      'hover:cursor-pointer',
      'disabled:cursor-not-allowed',
      className
    )}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Button>
);
