import { createContext } from 'react';

export const colorModeValues = ['light', 'dark', 'system'] as const;

export type ColorMode = (typeof colorModeValues)[number];

export type ColorModeContextValue = {
  mode: ColorMode;
  setMode: (mode: ColorMode) => void;
  systemIsDark: boolean;
};

export const ColorModeContext = createContext<ColorModeContextValue>({
  mode: 'system',
  setMode: () => {},
  systemIsDark: false,
});
