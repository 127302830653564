import React, { type FC, useState } from 'react';

import { FormButtons } from '@fragment/ui/src/components/Button/ButtonGroup/FormButtons';
import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';

export type DeleteFormProps = {
  /** Confirmation text ("Are you sure you want to do this?") */
  confirmText: string;
  buttonActionText: string;
  /** Will be displayed in buttons like "Yes, [Delete Text]" */
  confirmButtonText: string;
  /** Called once the user confirms their intent after the warning */
  onConfirm: () => void;
  fetching?: boolean;
  fetchingText?: string;
  disabled?: boolean;
};
export const DeleteForm: FC<DeleteFormProps> = ({
  confirmText,
  buttonActionText,
  confirmButtonText,
  onConfirm,
  fetching,
  fetchingText,
  disabled,
}) => {
  const [deleting, setDeleting] = useState(false);
  if (deleting) {
    return (
      <div className="space-y-f2">
        <p>{confirmText}</p>
        <FormButtons
          type="tertiary"
          onCancel={() => {
            setDeleting(false);
          }}
          onSubmit={onConfirm}
          cancelButtonLabel="Cancel"
          disabled={disabled}
          submitButtonLabel={
            fetching ? fetchingText || 'Deleting' : confirmButtonText
          }
        />
      </div>
    );
  }
  return (
    <PrimaryButtonV2 disabled={disabled} onClick={() => setDeleting(true)}>
      {buttonActionText}
    </PrimaryButtonV2>
  );
};
