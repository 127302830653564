import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

const DATE_FORMAT = 'yyyy-MM-dd';
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
const TIME_FORMAT = 'HH:mm';

const parseDate = (date: string | Date) => {
  if (typeof date === 'string') {
    const value = parseISO(date);
    return utcToZonedTime(value, 'UTC');
  }
  return utcToZonedTime(date, 'UTC');
};

/**
 * Formats a date to a string like '2022-01-31'
 * @param date An ISO-8601 date string, or a js Date object.
 * @returns A formatted date string, using UTC as the time zone.
 */
export const formatDate = (date: string | Date) =>
  format(parseDate(date), DATE_FORMAT, { timeZone: 'UTC' });

/**
 * Formats a date to a string like '2022-01-31 15:30'
 * @param date An ISO-8601 date string, or a js Date object.
 * @returns A formatted datetime string, using UTC as the time zone.
 */
export const formatDateTime = (date: string | Date) =>
  format(parseDate(date), DATETIME_FORMAT, { timeZone: 'UTC' });

export const formatTime = (date: string | Date) =>
  format(parseDate(date), TIME_FORMAT, { timeZone: 'UTC' });
