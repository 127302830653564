import type { FC, PropsWithChildren } from 'react';
import type { Client } from 'urql';
import { makeClient } from 'lib/urqlClient';
import React from 'react';
import { Provider } from 'urql';
import { useRoute } from 'wouter';

export const defaultClient = makeClient();
const clients: Record<string, Client> = {};

export const UrqlProvider: FC<PropsWithChildren> = ({ children }) => {
  const [match, params] = useRoute<{ workspaceId: string }>(
    '/w/:workspaceId/:rest*'
  );
  if (!match) {
    return <Provider value={defaultClient}>{children}</Provider>;
  }
  if (!new Set(Object.keys(clients)).has(params.workspaceId)) {
    clients[params.workspaceId] = makeClient();
  }
  return <Provider value={clients[params.workspaceId]}>{children}</Provider>;
};
