import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { FragmentEnv } from 'utils/env';
import { useRoute } from 'wouter';
import type { CacheLocation } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = FragmentEnv.auth0.domain;
const auth0ClientId = FragmentEnv.auth0.clientId;
const globalApiAuth0Audience = FragmentEnv.auth0.globalApiAudience;

// We override the usual token storage in cypress
// so that we can login via API and manually inject the token
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Cypress?: {
      env: (key: string) => string | number | boolean | undefined | null;
    };
  }
}
let cacheLocation: CacheLocation;
if (window.Cypress) {
  cacheLocation = 'localstorage';
}

export const USER_LOGIN_REDIRECT = '/loggedin';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  // If the user is in the increase onboard flow, we need to skip the redirect callback
  // because both Increase and our own Auth0 OAuth providers are configured to use the 'code'
  // query parameter. See: https://github.com/auth0/auth0-react/issues/75 for the recommended
  // solution.
  const [isUserLoginRedirectPage] = useRoute(USER_LOGIN_REDIRECT);

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${USER_LOGIN_REDIRECT}`,
        audience: globalApiAuth0Audience,
        scope: 'openid profile email',
      }}
      cacheLocation={cacheLocation}
      skipRedirectCallback={!isUserLoginRedirectPage}
    >
      {children}
    </Auth0Provider>
  );
};
