import type { UrqlOperationContext } from 'lib/urqlClient';
import React from 'react';
import { FragmentEnv } from 'utils/env';
import { useGlobalApiAccessToken } from 'wrappers/GlobalApiAccessTokenProvider';

const globalApiUrl =
  window.Cypress && !window.Cypress.env('realLogin')
    ? 'http://mock-global-api.localhost/test/graphql'
    : `${FragmentEnv.fragment.globalApi}/graphql`;

export function useGlobalApiContext() {
  const ctx = useGlobalApiAccessToken();
  const accessToken = ctx?.globalApiAccessToken;
  const context: UrqlOperationContext = React.useMemo(
    () => ({
      url: globalApiUrl,
      token: accessToken,
    }),
    [accessToken]
  );
  return {
    context,
    token: accessToken,
  };
}
