import type { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import type { InlineCopyProps } from '../../hooks/useInlineCopy';
import { useInlineCopy } from '../../hooks/useInlineCopy';

type Props = {
  name?: string;
  onCopyChildren: ReactNode;
  preventFocus?: boolean;
  'data-testid'?: string;
} & InlineCopyProps;

export const InlineCopy = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<Props>
>(
  (
    {
      'data-testid': dataTestId,
      name,
      children,
      onCopyChildren,
      preventFocus = false,
      ...rest
    },
    ref
  ) => {
    const {
      duration,
      textToCopy,
      stopEventPropagationAndDefault,
      onClick,
      onCopy,
      onCopySuccess,
      onCopyError,
    } = rest;
    const { handler, isCopied } = useInlineCopy({
      duration,
      textToCopy,
      stopEventPropagationAndDefault,
      onClick,
      onCopy,
      onCopySuccess,
      onCopyError,
    });

    return (
      <button
        name={name}
        type="button"
        disabled={isCopied}
        className={clsx(
          'text-main-500 hover:text-main transition-colors',
          !isCopied ? 'hover:cursor-pointer' : []
        )}
        onClick={handler}
        tabIndex={preventFocus ? -1 : undefined}
        data-testid={dataTestId}
        ref={ref}
      >
        {isCopied ? onCopyChildren : children}
      </button>
    );
  }
);
