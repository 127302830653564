import React from 'react';

import { type BaseSvgProps, colorToHexCode } from '../../utils';

export const ExitLeftB = ({
  primaryColorHexCode = colorToHexCode.black,
}: BaseSvgProps) => (
  <svg
    width="15"
    height="15"
    className="shrink-0"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1176 0H1.89386V14H2.60443V0.712899H11.4057V14H12.1176L12.1176 0Z"
      fill={primaryColorHexCode}
    />
    <path
      d="M2.57454 1.63038C3.20905 1.63038 3.69664 2.11797 3.69664 2.75586C3.69664 3.39494 3.20896 3.88253 2.57454 3.88253C2.57454 3.88253 2.57454 3.39494 2.57454 2.75586C2.57454 2.11797 2.55469 1.63038 2.57454 1.63038ZM2.71018 4.16149C3.81852 4.16149 6.03201 4.16509 6.03201 4.16509C6.50569 4.17978 6.58895 4.27329 6.77125 4.54439L7.80784 6.27823C8.12357 6.95257 7.18151 7.41623 6.83284 6.78047L5.98751 5.29963C5.97153 5.27227 5.94769 5.26553 5.91701 5.26659L4.74917 5.28022C5.06832 5.96135 5.38738 6.6416 5.7062 7.32512C5.80401 7.58024 5.83367 7.81387 5.86005 8.15099C5.90218 8.83 5.93988 9.51343 5.97952 10.1925C5.99209 10.2825 6.02166 10.3429 6.1299 10.3429C6.98331 10.3395 8.6156 10.3211 8.6156 10.3211C9.46318 10.3541 9.98376 11.048 9.98376 11.5733L5.41339 11.5628C5.00895 11.5502 4.70029 11.4342 4.66389 10.9773L4.52482 8.33787C3.78775 9.82112 3.04846 11.3032 2.31258 12.7863L2.43209 9.34081L3.11226 7.96754C3.16696 7.86386 3.09396 7.73067 3.06315 7.67144C2.78974 7.09168 2.62613 6.77616 2.35156 6.17579L2.13702 4.44978C2.13702 4.44978 2.3501 4.1535 2.71018 4.16149Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
