import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const ExpenseLine = ({
  'data-testid': testId,
  width = sizeToSvgWidth.fnormal,
  backgroundColorHexCode = colorToHexCode.white,
  primaryColorHexCode = colorToHexCode['expense-yellow'],
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.573975"
      y="0.5"
      width="12"
      height="12"
      rx="6"
      fill={backgroundColorHexCode}
    />
    <path
      d="M6.57397 6.5H12.574C12.574 9.81371 9.88768 12.5 6.57397 12.5V6.5Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
