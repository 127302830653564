import React from 'react';

import {
  ToastManagerContext,
  ToastRoot,
  useToastManager,
} from '../components/ToastManager';

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentToast, showToast, dismissCurrentToast } = useToastManager();
  const toastContext = React.useMemo(
    () => ({ showToast, dismissCurrentToast }),
    [showToast, dismissCurrentToast]
  );
  return (
    <ToastManagerContext.Provider value={toastContext}>
      {children}
      {currentToast && <ToastRoot>{currentToast}</ToastRoot>}
    </ToastManagerContext.Provider>
  );
};
