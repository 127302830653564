import type { PropsWithChildren } from 'react';
import React, { Children, isValidElement } from 'react';

import type { ListProps } from './types';
import { Text } from '../Text';
import { ListItem } from './ListItem';

export const List = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>({
  type,
  children,
  ...otherTextEngineProps
}: PropsWithChildren<
  ListProps<
    FontFamilyProp,
    FontWeightProp,
    FontStyleProp,
    FontSizeProp,
    FontDecorationProp,
    LineHeightProp,
    ColorProp,
    TextAlignProp,
    BackgroundProp,
    BorderWidthProp,
    BorderRadiusProp,
    BorderStyleProp,
    BorderColorProp,
    CursorProp,
    PositionProp,
    PlacementProp,
    DisplayProp,
    FlexContainerProp,
    FlexItemProp,
    WProp,
    HProp,
    MaxWProp,
    MaxHProp,
    MinWProp,
    MinHProp,
    PaddingProp,
    MarginProp,
    TransitionProp
  >
>) => {
  const list: 'ol' | 'ul' = type === 'ordered' ? 'ol' : 'ul';

  return (
    <Text
      data-testid="list-engine"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherTextEngineProps}
      as={list}
    >
      {Children.map(children, (child) =>
        isValidElement(child) &&
        (typeof child.type === 'string' ||
          (typeof child.type === 'function' &&
            child.type.name !== 'li' &&
            child.type !== ListItem)) ? (
          <ListItem>{child}</ListItem>
        ) : (
          child
        )
      )}
    </Text>
  );
};
