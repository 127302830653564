import React from 'react';
import { type ReactElement, createElement, isValidElement } from 'react';
import {
  type DefaultParams,
  type Match,
  type MatchWithParams,
  type RouteProps,
  useRoute,
} from 'wouter';

/*
 *
 * This component takes a function to applies a transformation to
 * the params.
 */

// Inspired from https://github.com/molefrog/wouter/blob/main/index.js#L93-L106

type BaseProps<T extends DefaultParams> = {
  transformer?: (params: T) => T;
  // `props.match` is present - Route is controlled by the Switch
  match?: Match<T>;
};

const isSuccessfulMatch = <T extends DefaultParams>(
  match: Match<T>
): match is MatchWithParams<T> => match[0];

export const ParamsTransformer = <T extends DefaultParams>({
  component,
  children,
  match,
  path = '',
  transformer = (params) => params,
}: RouteProps<T> & BaseProps<T>): ReactElement | null => {
  const useRouteMatch = useRoute<T>(path);
  const result = match ?? useRouteMatch;

  if (isSuccessfulMatch(result)) {
    const [, params] = result;
    const transformedParams = transformer(params);
    // React-Router style `component` prop
    if (component) {
      return createElement(component, { params: transformedParams });
    }

    const possibleElement =
      typeof children === 'function' ? children(transformedParams) : children;

    if (isValidElement(possibleElement)) {
      return possibleElement;
    }
    // converting from ReactNode to ReactElement so we can use as JSX
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{possibleElement}</>;
  }

  return null;
};
