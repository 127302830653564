import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Ledger: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <rect
      x="6.01221"
      y="6.5"
      width="6"
      height="6"
      fill={colorToHexCode['expense-yellow']}
    />
    <rect
      x="6.01221"
      y="0.5"
      width="6"
      height="6"
      fill={colorToHexCode['income-blue']}
    />
    <rect
      x="0.012207"
      y="6.5"
      width="6"
      height="6"
      fill={colorToHexCode['liability-red']}
    />
    <rect
      x="0.012207"
      y="0.5"
      width="6"
      height="6"
      fill={colorToHexCode['asset-green']}
    />
  </svg>
);
