/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import defaultCopy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { TWWidthProp } from '../../types/tailwind';
import type { TextSizeType } from '../utils';
import { TextButton } from '../Button/TextButton/TextButton';
import { TextInput } from '../Input/TextInput';
import { getTextSize } from '../utils';

type TextInputCopyProps<WProp> = {
  id: string;
  value: string;
  label?: string;
  size?: TextSizeType;
  width?: TWWidthProp<WProp>;
  border?: boolean;
  inline?: boolean;
  padding?: string;
  transparent?: boolean;
  extraClassNames?: string;
};

export const TextInputCopy = <WProp,>({
  id,
  value,
  label,
  size = 'fnormal',
  width,
  border = true,
  inline = false,
  padding,
  transparent = false,
  extraClassNames,
}: TextInputCopyProps<WProp>): JSX.Element => {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );
  const [isCopied, setIsCopied] = useState(false);
  const handler = useCallback(() => {
    if (!isCopied) {
      setIsCopied(true);
      defaultCopy(value);
      timerRef.current = setTimeout(() => {
        // Since we use an input element as the click target, we
        // need to blur it to prevent the focus/active styles from persisting.
        // We could do this with CSS too, although we'd have to make sure the
        // background colors are consistent.
        inputRef.current?.blur();
        setIsCopied(false);
      }, 1000);
    }
  }, [isCopied, value]);

  const labelContainerClasses = clsx(
    'flex items-center',
    !label && 'justify-end',
    label && 'justify-between',
    'h-f2'
  );
  const copyTarget = (
    <div
      className={clsx(
        'hidden group-hover/text-input-copy:inline-block',
        inline && 'group-hover/text-input-copy:bg-negative'
      )}
    >
      <TextButton onClick={handler}>{isCopied ? 'Copied!' : 'Copy'}</TextButton>
    </div>
  );
  return (
    <div
      className={clsx([
        size,
        width ?? 'w-f41',
        size === 'fnormal' && 'space-y-f1',
        'group/text-input-copy',
        inline && 'flex',
      ])}
    >
      <div className={labelContainerClasses}>
        {label && (
          <label htmlFor={id} className="shrink truncate">
            <span className={`text-main-500 ${getTextSize(size)}`}>
              {label}
            </span>
          </label>
        )}
        {!inline && copyTarget}
      </div>
      <TextInput
        data-testid="text-input-copy-input"
        ref={inputRef}
        id={id}
        border={border}
        value={value}
        padding={padding}
        size={size}
        readOnly
        width={width ?? ('w-f41' as TWWidthProp<WProp>)}
        onClick={handler}
        className="hover:cursor-pointer"
        transparent={transparent}
        extraClassNames={extraClassNames}
      />
      {inline && copyTarget}
    </div>
  );
};
