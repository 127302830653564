import React from 'react';

import { type BaseSvgProps } from '../../utils';

export const SuccessToastIcon = ({
  width = 12,
  backgroundColorHexCode = '#FFF',
  'data-testid': testId,
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM9.35 4.02946L4.83539 8.55529L2.25 5.97551L2.8613 5.32496L4.83539 7.25979L8.7387 3.37891L9.35 4.02946Z"
      fill={backgroundColorHexCode}
    />
  </svg>
);
