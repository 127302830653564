import React, { useCallback, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Icon } from '@fragment/ui/src/components/Icon';
import { Table } from '@fragment/ui/src/components/Table';

import { useGetDataExportsQuery } from '../../../../hooks/internalApi';
import { useInternalApiContext } from '../../../../hooks/useInternalApiContext';
import { getResultOrError } from '../../../../utils/errors';
import { useWorkspaceId } from '../../../../wrappers/WorkspaceProvider';
import { NewDataExportForm } from '../../../dataExport/NewDataExportForm';
import { DataExportDetail } from '../../../settings/DataExportDetail';
import { TableLoadError } from '../../../TableLoadError/TableLoadError';

export type DataExportRow = {
  id: string;
  exportBucketName: string;
  exportBucketRegion: string;
  exportBucketKey?: string | null;
};
const schema = [
  {
    title: 'Name',
    key: 'id',
  },
  {
    title: 'Bucket',
    key: 'exportBucketName',
    justify: 'right',
  },
] as const;

const emptyValue = {
  Bucket: 'N/A',
  Name: 'No Data Exports yet',
};
const DataExportsTable = ({
  onRowClick,
}: {
  onRowClick: (row: DataExportRow) => void;
}) => {
  const { context, token } = useInternalApiContext();
  const workspaceId = useWorkspaceId();
  const variables = useMemo(
    () => ({
      workspaceId,
    }),
    [workspaceId]
  );
  const [{ data, error, fetching }, reExecuteQuery] = useGetDataExportsQuery({
    variables,
    context,
    pause: !token,
  });
  const refreshTable = useCallback(
    () => reExecuteQuery({ requestPolicy: 'network-only' }),
    [reExecuteQuery]
  );
  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );
  const { isError: isDataExportsError, result: dataExports } = getResultOrError(
    workspace?.dataExports,
    error
  );
  if (isWorkspaceError || isDataExportsError) {
    return (
      <TableLoadError
        entityColumnName="Name"
        rightColumnName="Bucket"
        {...{ error, retry: refreshTable, fetching }}
      />
    );
  }
  return (
    <Table
      data={dataExports.nodes}
      onRowClick={onRowClick}
      schema={schema}
      emptyValue={emptyValue}
      textSize="s20"
    />
  );
};

export const DataExportsSettingsForm = () => {
  const [clickedDataExport, setClickedDataExport] = useState<DataExportRow>();
  const [isCreatingDataExport, setIsCreatingDataExport] =
    useState<boolean>(false);

  const handleBack = useCallback(() => {
    setClickedDataExport(undefined);
    setIsCreatingDataExport(false);
  }, []);
  return (
    <div className="flex flex-col space-y-f4">
      <div className="flex justify-between">
        {isCreatingDataExport || clickedDataExport ? (
          <TertiaryButton onClick={handleBack}>
            <Icon type="left" size="text-s20" />
            <span className="ml-[1ch]">Back</span>
          </TertiaryButton>
        ) : (
          <>
            <span>Data Exports</span>
            <PrimaryButtonV2
              onClick={() => setIsCreatingDataExport(true)}
              secondaryIcon="+"
            >
              New Export
            </PrimaryButtonV2>
          </>
        )}
      </div>
      {match([isCreatingDataExport, clickedDataExport])
        // Creating a new data export
        .with([true, P._], () => (
          <NewDataExportForm onSuccess={handleBack} onCancel={handleBack} />
        ))
        // Data exports list
        .with([P.boolean, undefined], () => (
          <DataExportsTable onRowClick={setClickedDataExport} />
        ))
        // Selected a data export from the table
        .with([P.boolean, P.select()], (selected) => (
          <DataExportDetail
            onConfirmedDelete={handleBack}
            dataExport={selected!}
          />
        ))
        .exhaustive()}
    </div>
  );
};
