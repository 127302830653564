import { LoginView } from 'components/login/LoginView';
import { useUserInfo } from 'hooks/useUserInfo';
import React from 'react';
import { Redirect } from 'wouter';
import { useAuth0 } from '@auth0/auth0-react';

export const DefaultLoginRedirect = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const userInfo = useUserInfo();
  const loading =
    isLoading || (isAuthenticated && userInfo.type === 'not_logged_in');
  if (isAuthenticated && userInfo.data) {
    return <Redirect href="/" replace />;
  }

  return <LoginView loading={loading} />;
};
