import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { colorToHexCode } from '../../utils';

export const ProhibitedCircle: FC<BaseSvgProps> = ({
  'data-testid': dataTestId,
  primaryColorHexCode = colorToHexCode.gray,
}) => (
  <svg
    width="13"
    height="13"
    className="shrink-0"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.30662 10.1681C8.57101 10.6919 7.67109 11 6.69922 11C4.21394 11 2.19922 8.98528 2.19922 6.5C2.19922 5.52813 2.50731 4.62821 3.03116 3.8926L9.30662 10.1681ZM10.3673 9.1074L4.09182 2.83194C4.82743 2.30809 5.72735 2 6.69922 2C9.1845 2 11.1992 4.01472 11.1992 6.5C11.1992 7.47187 10.8911 8.37179 10.3673 9.1074ZM12.6992 6.5C12.6992 9.81371 10.0129 12.5 6.69922 12.5C3.38551 12.5 0.699219 9.81371 0.699219 6.5C0.699219 3.18629 3.38551 0.5 6.69922 0.5C10.0129 0.5 12.6992 3.18629 12.6992 6.5Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
