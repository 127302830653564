import React from 'react';

import { type BaseSvgProps } from '../../utils';

export const ErrorToastIcon = ({
  width = 12,
  backgroundColorHexCode = '#FFF',
  'data-testid': testId,
}: BaseSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    data-testid={testId}
  >
    <defs>
      <mask id="a">
        <rect width="100%" height="100%" fill="#fff" />
        <path d="M8.46 9 3 3.54 3.54 3 9 8.46 8.46 9Z" fill="#000" />
        <path d="M3 8.46 8.46 3l.54.54L3.54 9 3 8.46Z" fill="#000" />
      </mask>
    </defs>
    <rect
      width="12"
      height="12"
      rx="6"
      mask="url(#a)"
      fill={backgroundColorHexCode}
    />
  </svg>
);
