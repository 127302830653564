import clsx from 'clsx';
import React from 'react';

import type { BaseRowType, BaseTableProps } from '../helpers';
import { FillSpaceWithDots } from '../../FillSpaceWithDots';
import { gridClasses } from '../helpers';

export const Header = <T extends BaseRowType>({
  schema,
  textSize,
  dottedHeader,
}: Pick<BaseTableProps<T>, 'schema' | 'textSize' | 'dottedHeader'>) => (
  <div className={gridClasses(textSize)} role="row">
    {schema.map(({ justify, colspan, title }) => (
      <div
        key={title}
        // These aren't on text engine yet
        className={clsx(
          justify === 'right' ? 'text-right' : 'text-left',
          colspan,
          justify === 'right' ? '-ml-f2' : ''
        )}
      >
        <div
          className={`text-main-500 ${
            textSize === 'fnormal' ? 'h-f3' : 'h-f2'
          } flex flex-row`}
          role="columnheader"
        >
          {justify === 'right' && dottedHeader && (
            <FillSpaceWithDots
              marginClassName={
                textSize === 's20' ? 'mb-[7px]' : 'mb-[13px] mr-[4px] ml-f2'
              }
            />
          )}
          <span
            className={clsx([
              'flex flex-shrink-0',
              justify === 'right' && 'ml-auto',
            ])}
          >
            {title}
          </span>
          {justify !== 'right' && dottedHeader && (
            <FillSpaceWithDots
              marginClassName={
                textSize === 's20' ? 'mb-[7px]' : 'mb-[13px] -mr-f2'
              }
            />
          )}
        </div>
      </div>
    ))}
  </div>
);
