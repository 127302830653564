import type { ReactNode } from 'react';
import clsx from 'clsx';
import React from 'react';

import type { TWMarginProp } from '@fragment/ui/src/types/tailwind';

import { Icon } from '../Icon/BaseIcon/Icon';

type Props<MarginProp> = {
  singleLine?: boolean;
  children: Exclude<ReactNode, boolean | null | undefined>;
  margin?: TWMarginProp<MarginProp>;
};

export const InlineError = <MarginProp,>({
  children,
  singleLine = false,
  margin = 'mt-f2' as TWMarginProp<MarginProp>,
}: Props<MarginProp>) => {
  if (!children) {
    // eslint-disable-next-line
    console.warn(
      'An empty child prop was received - no error text will be rendered'
    );
  }

  return (
    <div className={clsx('flex items-center', margin)}>
      <Icon type="error" backgroundColor="orange" />
      {singleLine ? (
        <span className="truncate">{children}</span>
      ) : (
        <span>{children}</span>
      )}
    </div>
  );
};
