import type { MutableRefObject } from 'react';
import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

export const useIsOverflow = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>
) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const { current } = ref;

  useIsomorphicLayoutEffect(() => {
    if (current) {
      new ResizeObserver(() =>
        setIsOverflow(
          current.scrollWidth > current.clientWidth ||
            current.scrollHeight > current.clientHeight
        )
      ).observe(current);
    }
  }, [current]);

  return isOverflow;
};
