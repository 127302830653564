/* eslint-disable react/jsx-props-no-spreading */
import type { ReactNode } from 'react';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { type Config, usePopperTooltip } from 'react-popper-tooltip';

import type {
  TWLineClampProp,
  TWMaxWidthProp,
  TWPaddingProp,
  TWTextColorProp,
} from '../../types/tailwind';
import { useIsOverflow } from '../../hooks/useIsOverflow';

export const TOOLTIP_CONTAINER_CLASSNAME = 'tooltip-container';

type Props<MaxWProp, ColorProp, LineClampProp, PaddingProp> = {
  baseComponent: ReactNode;
  overflowComponent: ReactNode;
  lineClamp?: TWLineClampProp<LineClampProp>;
  color?: TWTextColorProp<ColorProp>;
  padding?: TWPaddingProp<PaddingProp>;
  maxW: TWMaxWidthProp<MaxWProp>;
  placement?: Config['placement'];
  tooltipRef?: React.RefObject<HTMLDivElement>;
};

export const OverflowTooltip = <
  MaxWProp,
  ColorProp,
  LineClampProp,
  PaddingProp
>({
  maxW,
  lineClamp,
  color,
  baseComponent,
  overflowComponent,
  padding,
  placement = 'bottom-start',
  tooltipRef,
}: Props<MaxWProp, ColorProp, LineClampProp, PaddingProp>): JSX.Element => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    followCursor: true,
    placement,
    offset: [-45, 9],
    delayShow: 250,
    interactive: true,
  });
  const textRef = useRef<HTMLSpanElement | null>(null);
  const isOverflow = useIsOverflow<HTMLSpanElement>(textRef);

  const tooltip = (
    <div
      ref={setTooltipRef}
      {...getTooltipProps({
        className: clsx(
          padding,
          TOOLTIP_CONTAINER_CLASSNAME,
          'border bg-negative border-canvas',
          'z-30',
          'flex items-center'
        ),
      })}
    >
      <div {...getArrowProps({ className: 'tooltip-arrow' })} />
      {overflowComponent}
    </div>
  );

  return (
    <div className="min-w-f0">
      <div
        className={clsx(maxW, 'truncate', color, lineClamp)}
        ref={(el) => {
          setTriggerRef(el);
          textRef.current = el;
        }}
      >
        {baseComponent}
      </div>
      {visible &&
        isOverflow &&
        !!overflowComponent &&
        (tooltipRef?.current
          ? createPortal(tooltip, tooltipRef.current)
          : tooltip)}
    </div>
  );
};
