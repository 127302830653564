import type { FC } from 'react';
import assert from 'assert';
import { useTestDataExportConnectionMutation } from 'hooks/internalApi';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useCallback, useState } from 'react';
import { getResultOrError } from 'utils/errors';

import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Icon } from '@fragment/ui/src/components/Icon';

type Props = {
  bucketName?: string;
  bucketRegion?: string;
};

export const TestConnectionButton: FC<Props> = ({
  bucketName,
  bucketRegion,
}) => {
  const { context } = useInternalApiContext();
  const [{ fetching }, testDataExportConnection] =
    useTestDataExportConnectionMutation();
  const [connectionTested, setConnectionTested] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleTestConnection = useCallback(async () => {
    if (bucketName && bucketRegion) {
      setErrorMessage('');
      setConnectionTested(true);
      const { data, error } = await testDataExportConnection(
        {
          bucketName,
          bucketRegion,
        },
        context
      );
      if (
        data?.testDataExportConnection?.__typename ===
        'TestDataExportConnectionResult'
      ) {
        setErrorMessage(data.testDataExportConnection.errorMessage || null);
      } else {
        const res = getResultOrError(data?.testDataExportConnection, error);
        assert(res.isError);
        setErrorMessage(res.errorMessage);
      }
    }
  }, [testDataExportConnection, bucketName, bucketRegion, context]);

  const disabled = !(bucketName && bucketRegion) || fetching;
  return (
    <div className="flex flex-wrap gap-x-f2">
      <TertiaryButton
        type="button"
        data-testid="test-connection-button"
        disabled={disabled}
        onClick={handleTestConnection}
      >
        <Icon type="rotate" size="text-s20" />
        &nbsp;
        {fetching ? 'Testing...' : 'Test Connection'}
      </TertiaryButton>
      {!fetching &&
        connectionTested &&
        (!errorMessage ? (
          <div className="flex items-center gap-f1">
            <Icon type="success" backgroundColor="black" />
            <span>Verified Permissions</span>
          </div>
        ) : (
          <div
            data-testid="test-connection-error"
            className="flex items-center gap-f1"
          >
            <Icon type="error" backgroundColor="orange" />
            <span>{errorMessage}</span>
          </div>
        ))}
    </div>
  );
};
