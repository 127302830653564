/* This file was generated by `yarn gen-graphql`. Don't edit it directly */

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A string that must be alphanumeric */
  AlphaNumericString: { input: string; output: string };
  /** ISO 8601 Date e.g. `1969-07-21` */
  Date: { input: string; output: string };
  /** ISO 8601 DateTime e.g. `1969-07-16T13:32:00.000Z`. You can also provide a date e.g. `1969-01-01` and it will be converted to `1969-01-01T00:00:00.000Z` */
  DateTime: { input: string; output: string };
  /** A string representing integers up to 9,223,372,036,854,775,807 (i.e. 2^63-1) */
  Int64: { input: string; output: string };
  /** A string representing integers as big as 2^96-1. The number is signed so the range is from -79,228,162,514,264,337,593,543,950,336 to 79,228,162,514,264,337,593,543,950,336. */
  Int96: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: unknown; output: unknown };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: string; output: string };
  /** The last moment of a specific year, month or day or hour e.g. 1969 or 1969-12 or 1969-12-31 or 1969-12-31T23. All of the previous examples are equivalent to `1969-12-31T23:59:59.999`. */
  LastMoment: { input: string; output: string };
  /** A string of non-zero length that can contain parameterized values via handlebars syntax. ex: `"Hello from {{country}}"`. */
  ParameterizedString: { input: string; output: string };
  /** A mapping of parameter keys to values. */
  Parameters: { input: string; output: string };
  /** A specific year ("2021"), quarter ("2021-Q1"), month ("2021-02"), day ("2021-02-03") or hour ("2021-02-03T04") */
  Period: { input: string; output: string };
  /** A string with delimiter characters `/`, `#`, and `:` disallowed, as well as parameters in {{handlebar}} syntax. */
  SafeString: { input: string; output: string };
  /** All hour-aligned offsets from -11:00 to +12:00 are supported, e.g. "-08:00" (PT), "-05:00" (ET), "+00:00" (UTC) */
  UTCOffset: { input: string; output: string };
};

export type ApiClient = {
  __typename?: 'ApiClient';
  clientName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AuthLinkUnit = {
  __typename?: 'AuthLinkUnit';
  env: LinkUnitEnv;
  id: Scalars['ID']['output'];
  unitOrgId: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type AuthLinkUnitResponse = AuthLinkUnitResult | BadRequestError | InternalError;

export type AuthLinkUnitResult = {
  __typename?: 'AuthLinkUnitResult';
  authLink: AuthLinkUnit;
};

/** Equivalent to an HTTP 400 - request either has missing or incorrect data */
export type BadRequestError = Error & {
  __typename?: 'BadRequestError';
  /** The HTTP status code corresponding to the error */
  code: Scalars['String']['output'];
  /** The error message */
  message: Scalars['String']['output'];
  /** Whether or not the operation is retryable */
  retryable: Scalars['Boolean']['output'];
};

export type BnkDevTempLink = {
  __typename?: 'BnkDevTempLink';
  id: Scalars['ID']['output'];
};

export type ChatCompletion = {
  __typename?: 'ChatCompletion';
  id: Scalars['ID']['output'];
  response?: Maybe<Scalars['String']['output']>;
  status: ChatCompletionStatus;
};

export enum ChatCompletionContentType {
  ImageUrl = 'image_url',
  Text = 'text',
}

export type ChatCompletionImageContent = {
  image_url?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChatCompletionContentType>;
};

export type ChatCompletionMessage = {
  content?: InputMaybe<Scalars['String']['input']>;
  imageContent?: InputMaybe<Array<InputMaybe<ChatCompletionImageContent>>>;
  role: ChatCompletionRole;
};

export type ChatCompletionResponse = BadRequestError | ChatCompletion | InternalError | NotFoundError;

export enum ChatCompletionRole {
  Assistant = 'assistant',
  System = 'system',
  User = 'user',
}

export enum ChatCompletionStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  Pending = 'pending',
}

export type CreateApiClientResponse = BadRequestError | CreateApiClientResult | InternalError;

export type CreateApiClientResult = {
  __typename?: 'CreateApiClientResult';
  client: ApiClient;
  secret: Scalars['String']['output'];
  workspace: Workspace;
};

export type CreateCustomLinkResponse = BadRequestError | CreateCustomLinkResult | InternalError;

export type CreateCustomLinkResult = {
  __typename?: 'CreateCustomLinkResult';
  isIkReplay: Scalars['Boolean']['output'];
  link: CustomLink | IncreaseLink | StripeLink | UnitLink;
};

export type CreateDataExportInput = {
  exportBucketKey?: InputMaybe<Scalars['String']['input']>;
  exportBucketName: Scalars['String']['input'];
  exportBucketRegion: Scalars['String']['input'];
  exportTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
};

export type CreateDataExportResponse = BadRequestError | CreateDataExportResult | InternalError;

export type CreateDataExportResult = {
  __typename?: 'CreateDataExportResult';
  dataExport: DataExport;
  workspace: Workspace;
};

export type CreateUserInput = {
  authId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateWorkspaceAccessResponse = BadRequestError | CreateWorkspaceAccessResult | InternalError;

export type CreateWorkspaceAccessResult = {
  __typename?: 'CreateWorkspaceAccessResult';
  success: Scalars['Boolean']['output'];
  workspaceAccess?: Maybe<WorkspaceAccess>;
};

export type CreateWorkspaceSettingsResponse = BadRequestError | CreateWorkspaceSettingsResult | InternalError;

export type CreateWorkspaceSettingsResult = {
  __typename?: 'CreateWorkspaceSettingsResult';
  success: Scalars['Boolean']['output'];
  workspace: Workspace;
  workspaceAccess: WorkspaceAccess;
};

export type CreateWorkspaceSettingsUserInput = {
  authId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkspaceSettingsWorkspaceInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum CurrencyCode {
  Aave = 'AAVE',
  Ada = 'ADA',
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bch = 'BCH',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btc = 'BTC',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Custom = 'CUSTOM',
  Cve = 'CVE',
  Czk = 'CZK',
  Dai = 'DAI',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eth = 'ETH',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Link = 'LINK',
  Lkr = 'LKR',
  Logical = 'LOGICAL',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltc = 'LTC',
  Lyd = 'LYD',
  Mad = 'MAD',
  Matic = 'MATIC',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pts = 'PTS',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sol = 'SOL',
  Sos = 'SOS',
  Spl = 'SPL',
  Srd = 'SRD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Tvd = 'TVD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Uni = 'UNI',
  Usd = 'USD',
  Usdc = 'USDC',
  Usdt = 'USDT',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xlm = 'XLM',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
}

export type CustomLink = Link & {
  __typename?: 'CustomLink';
  created: Scalars['String']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  originalName: Scalars['String']['output'];
};

export type DataExport = {
  __typename?: 'DataExport';
  exportBucketKey?: Maybe<Scalars['String']['output']>;
  exportBucketName: Scalars['String']['output'];
  exportBucketRegion: Scalars['String']['output'];
  exportTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  lastPublishedExportTimestamp?: Maybe<Scalars['String']['output']>;
};

export type DeleteApiClientResponse = BadRequestError | DeleteApiClientResult | InternalError;

export type DeleteApiClientResult = {
  __typename?: 'DeleteApiClientResult';
  success: Scalars['Boolean']['output'];
};

export type DeleteDataExportResponse = BadRequestError | DeleteDataExportResult | InternalError;

export type DeleteDataExportResult = {
  __typename?: 'DeleteDataExportResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteWorkspaceAccessResponse = BadRequestError | DeleteWorkspaceAccessResult | InternalError;

export type DeleteWorkspaceAccessResult = {
  __typename?: 'DeleteWorkspaceAccessResult';
  success: Scalars['Boolean']['output'];
};

export enum Entity {
  BnkDevLink = 'BnkDevLink',
  CustomLink = 'CustomLink',
  StripeLink = 'StripeLink',
  UnitLink = 'UnitLink',
}

/** Base error interface */
export type Error = {
  /** The HTTP status code corresponding to the error */
  code: Scalars['String']['output'];
  /** The error message */
  message: Scalars['String']['output'];
  /** Whether or not the operation is retryable */
  retryable: Scalars['Boolean']['output'];
};

export type IncreaseLink = Link & {
  __typename?: 'IncreaseLink';
  created: Scalars['String']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  increaseEnv: OnboardBnkDevEnv;
  originalName: Scalars['String']['output'];
};

/** Equivalent to an HTTP 5XX - something went wrong with our API. */
export type InternalError = Error & {
  __typename?: 'InternalError';
  /** The HTTP status code corresponding to the error */
  code: Scalars['String']['output'];
  /** The error message */
  message: Scalars['String']['output'];
  /** Whether or not the operation is retryable */
  retryable: Scalars['Boolean']['output'];
};

export enum InvoiceStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  Void = 'void',
}

export type Link = {
  created: Scalars['String']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  originalName: Scalars['String']['output'];
};

export type LinkBnkDevResponse = BadRequestError | InternalError | LinkBnkDevResult;

export type LinkBnkDevResult = {
  __typename?: 'LinkBnkDevResult';
  link?: Maybe<BnkDevTempLink>;
};

export type LinkResponse =
  | BadRequestError
  | CustomLink
  | IncreaseLink
  | InternalError
  | NotFoundError
  | StripeLink
  | UnitLink;

export enum LinkUnitEnv {
  Production = 'production',
  Sandbox = 'sandbox',
}

export type LinkUnitResponse = BadRequestError | InternalError | LinkUnitResult;

export type LinkUnitResult = {
  __typename?: 'LinkUnitResult';
  link: CustomLink | IncreaseLink | StripeLink | UnitLink;
};

export type ListWorkspaceMembersResponse = BadRequestError | InternalError | ListWorkspaceMembersResult | NotFoundError;

export type ListWorkspaceMembersResult = {
  __typename?: 'ListWorkspaceMembersResult';
  nodes: Array<WorkspaceMember>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  authLinkUnit?: Maybe<AuthLinkUnitResponse>;
  createApiClient?: Maybe<CreateApiClientResponse>;
  createCustomLink?: Maybe<CreateCustomLinkResponse>;
  createDataExport?: Maybe<CreateDataExportResponse>;
  createWorkspaceAccess?: Maybe<CreateWorkspaceAccessResponse>;
  createWorkspaceSettings?: Maybe<CreateWorkspaceSettingsResponse>;
  deleteApiClient?: Maybe<DeleteApiClientResponse>;
  deleteDataExport?: Maybe<DeleteDataExportResponse>;
  deleteWorkspaceAccess?: Maybe<DeleteWorkspaceAccessResponse>;
  linkBnkDev?: Maybe<LinkBnkDevResponse>;
  linkUnit?: Maybe<LinkUnitResponse>;
  onboardBnkDev?: Maybe<OnboardBnkDevResponse>;
  onboardStripe?: Maybe<OnboardStripeResponse>;
  saveSchemaDraft: SaveSchemaDraftResponse;
  sendChatCompletionRequest: SendChatCompletionRequestResponse;
  testDataExportConnection?: Maybe<TestDataExportConnectionResponse>;
  updateStripeLink: UpdateStripeLinkResponse;
  updateUnitLink: UpdateUnitLinkResponse;
  updateWorkspace?: Maybe<UpdateWorkspaceResponse>;
};

export type MutationAuthLinkUnitArgs = {
  env: LinkUnitEnv;
  unitOrgId: Scalars['String']['input'];
  workspaceId: Scalars['String']['input'];
};

export type MutationCreateApiClientArgs = {
  clientName: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationCreateCustomLinkArgs = {
  ik: Scalars['SafeString']['input'];
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationCreateDataExportArgs = {
  dataExport: CreateDataExportInput;
  workspaceId: Scalars['ID']['input'];
};

export type MutationCreateWorkspaceAccessArgs = {
  authId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationCreateWorkspaceSettingsArgs = {
  user: CreateWorkspaceSettingsUserInput;
  workspace: CreateWorkspaceSettingsWorkspaceInput;
};

export type MutationDeleteApiClientArgs = {
  clientId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationDeleteDataExportArgs = {
  dataExportId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationDeleteWorkspaceAccessArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationLinkBnkDevArgs = {
  code: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationLinkUnitArgs = {
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  env: LinkUnitEnv;
  name: Scalars['String']['input'];
  orgToken: Scalars['String']['input'];
  unitOrgId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationOnboardBnkDevArgs = {
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  env: OnboardBnkDevEnv;
  name?: InputMaybe<Scalars['String']['input']>;
  tempLinkId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationOnboardStripeArgs = {
  code: Scalars['String']['input'];
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  env: StripeEnv;
  name?: InputMaybe<Scalars['String']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type MutationSaveSchemaDraftArgs = {
  author: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type MutationSendChatCompletionRequestArgs = {
  messages: Array<ChatCompletionMessage>;
  workspaceId: Scalars['String']['input'];
};

export type MutationTestDataExportConnectionArgs = {
  bucketName: Scalars['String']['input'];
  bucketRegion?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateStripeLinkArgs = {
  input: UpdateStripeLinkInput;
};

export type MutationUpdateUnitLinkArgs = {
  input: UpdateUnitLinkInput;
};

export type MutationUpdateWorkspaceArgs = {
  update: UpdateWorkspaceInput;
  workspaceId: Scalars['ID']['input'];
};

/** Equivalent to an HTTP 404 */
export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  /** The HTTP status code corresponding to the error */
  code: Scalars['String']['output'];
  /** The error message */
  message: Scalars['String']['output'];
  /** Whether or not the operation is retryable */
  retryable: Scalars['Boolean']['output'];
};

export enum OnboardBnkDevEnv {
  Production = 'production',
  Sandbox = 'sandbox',
}

export type OnboardBnkDevResponse = BadRequestError | InternalError | OnboardBnkDevResult;

export type OnboardBnkDevResult = {
  __typename?: 'OnboardBnkDevResult';
  link?: Maybe<CustomLink | IncreaseLink | StripeLink | UnitLink>;
};

export type OnboardStripeResponse = BadRequestError | InternalError | OnboardStripeResult;

export type OnboardStripeResult = {
  __typename?: 'OnboardStripeResult';
  link?: Maybe<CustomLink | IncreaseLink | StripeLink | UnitLink>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  /** Query a Ledger Entry Group given its Ledger, key, and value. */
  chatCompletion: ChatCompletionResponse;
  listWorkspaceMembers?: Maybe<ListWorkspaceMembersResponse>;
  schemaDraft: SchemaDraftResponse;
  schemaDrafts: SchemaDraftConnection;
  workspace?: Maybe<WorkspaceResponse>;
};

export type QueryChatCompletionArgs = {
  id: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type QueryListWorkspaceMembersArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type QuerySchemaDraftArgs = {
  created: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type QuerySchemaDraftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SchemaDraftFilterSet>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};

export type ReversiblePageInfo = {
  __typename?: 'ReversiblePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type SaveSchemaDraftResponse = BadRequestError | InternalError | SaveSchemaDraftResult;

export type SaveSchemaDraftResult = {
  __typename?: 'SaveSchemaDraftResult';
  draft: SchemaDraft;
};

export type SchemaDraft = {
  __typename?: 'SchemaDraft';
  author: Scalars['String']['output'];
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  json: Scalars['JSON']['output'];
  schemaKey: Scalars['SafeString']['output'];
};

export type SchemaDraftConnection = {
  __typename?: 'SchemaDraftConnection';
  nodes: Array<SchemaDraft>;
  pageInfo: ReversiblePageInfo;
};

export type SchemaDraftFilterSet = {
  author?: InputMaybe<Scalars['String']['input']>;
};

export type SchemaDraftMatchInput = {
  id: Scalars['ID']['input'];
};

export type SchemaDraftResponse = BadRequestError | InternalError | NotFoundError | SchemaDraft;

export type SendChatCompletionRequestResponse = BadRequestError | ChatCompletion | InternalError;

export enum StripeEnv {
  Livemode = 'livemode',
  Testmode = 'testmode',
}

export type StripeLink = Link & {
  __typename?: 'StripeLink';
  created: Scalars['String']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  originalName: Scalars['String']['output'];
  rakLastUpdated?: Maybe<Scalars['String']['output']>;
  stripeEnv: StripeEnv;
};

export type TempLink = {
  __typename?: 'TempLink';
  id: Scalars['ID']['output'];
  institutionName: Scalars['String']['output'];
};

export type TestDataExportConnectionResponse = BadRequestError | InternalError | TestDataExportConnectionResult;

export type TestDataExportConnectionResult = {
  __typename?: 'TestDataExportConnectionResult';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum UnitEnv {
  Production = 'production',
  Sandbox = 'sandbox',
}

export type UnitLink = Link & {
  __typename?: 'UnitLink';
  created: Scalars['String']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  orgTokenLastUpdated?: Maybe<Scalars['String']['output']>;
  originalName: Scalars['String']['output'];
  unitEnv: UnitEnv;
};

export type UpdateStripeLinkInput = {
  linkId: Scalars['ID']['input'];
  rak: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateStripeLinkResponse = BadRequestError | InternalError | UpdateStripeLinkResult;

export type UpdateStripeLinkResult = {
  __typename?: 'UpdateStripeLinkResult';
  link?: Maybe<StripeLink>;
};

export type UpdateUnitLinkInput = {
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  linkId: Scalars['ID']['input'];
  orgToken: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type UpdateUnitLinkResponse = BadRequestError | InternalError | UpdateUnitLinkResult;

export type UpdateUnitLinkResult = {
  __typename?: 'UpdateUnitLinkResult';
  link?: Maybe<UnitLink>;
};

export type UpdateWorkspaceInput = {
  defaultCurrency?: InputMaybe<CurrencyCode>;
  name?: InputMaybe<Scalars['String']['input']>;
  octaneCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceResponse = BadRequestError | InternalError | UpdateWorkspaceResult;

export type UpdateWorkspaceResult = {
  __typename?: 'UpdateWorkspaceResult';
  workspace: Workspace;
};

export type Workspace = {
  __typename?: 'Workspace';
  apiClients?: Maybe<WorkspaceApiClientsResponse>;
  dataExports?: Maybe<WorkspaceDataExportsResponse>;
  defaultCurrency: CurrencyCode;
  id: Scalars['ID']['output'];
  inviteCode?: Maybe<Scalars['String']['output']>;
  inviteEnabled?: Maybe<Scalars['Boolean']['output']>;
  invoices?: Maybe<WorkspaceInvoicesResponse>;
  link?: Maybe<LinkResponse>;
  links?: Maybe<WorkspaceLinksResponse>;
  members?: Maybe<WorkspaceMembersResponse>;
  name: Scalars['String']['output'];
  octaneCustomerId?: Maybe<Scalars['String']['output']>;
  tempLinks?: Maybe<WorkspaceTempLinksResponse>;
  token: Scalars['String']['output'];
};

export type WorkspaceLinkArgs = {
  id: Scalars['ID']['input'];
};

export type WorkspaceAccess = {
  __typename?: 'WorkspaceAccess';
  authId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  workspaceId: Scalars['String']['output'];
};

export type WorkspaceApiClientsResponse = BadRequestError | InternalError | NotFoundError | WorkspaceApiClientsResult;

export type WorkspaceApiClientsResult = {
  __typename?: 'WorkspaceApiClientsResult';
  nodes: Array<ApiClient>;
};

export type WorkspaceDataExportsResponse = BadRequestError | InternalError | NotFoundError | WorkspaceDataExportsResult;

export type WorkspaceDataExportsResult = {
  __typename?: 'WorkspaceDataExportsResult';
  nodes: Array<DataExport>;
};

export type WorkspaceInvoice = {
  __typename?: 'WorkspaceInvoice';
  amount: Scalars['Int96']['output'];
  billingPeriod: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  status: InvoiceStatus;
};

export type WorkspaceInvoicesConnection = {
  __typename?: 'WorkspaceInvoicesConnection';
  nodes: Array<WorkspaceInvoice>;
};

export type WorkspaceInvoicesResponse = BadRequestError | InternalError | NotFoundError | WorkspaceInvoicesConnection;

export type WorkspaceLinksConnection = {
  __typename?: 'WorkspaceLinksConnection';
  nodes: Array<CustomLink | IncreaseLink | StripeLink | UnitLink>;
};

export type WorkspaceLinksResponse = BadRequestError | InternalError | NotFoundError | WorkspaceLinksConnection;

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type WorkspaceMembersResponse = BadRequestError | InternalError | NotFoundError | WorkspaceMembersResult;

export type WorkspaceMembersResult = {
  __typename?: 'WorkspaceMembersResult';
  nodes: Array<WorkspaceMember>;
};

export type WorkspaceResponse = BadRequestError | InternalError | NotFoundError | Workspace;

export type WorkspaceTempLinksConnection = {
  __typename?: 'WorkspaceTempLinksConnection';
  nodes: Array<TempLink>;
};

export type WorkspaceTempLinksResponse = BadRequestError | InternalError | NotFoundError | WorkspaceTempLinksConnection;

export type GetChatCompletionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
}>;

export type GetChatCompletionQuery = {
  __typename?: 'Query';
  chatCompletion:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'ChatCompletion'; id: string; status: ChatCompletionStatus; response?: string | null }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string };
};

export type SendChatCompletionRequestMutationVariables = Exact<{
  workspaceId: Scalars['String']['input'];
  messages: Array<ChatCompletionMessage> | ChatCompletionMessage;
}>;

export type SendChatCompletionRequestMutation = {
  __typename?: 'Mutation';
  sendChatCompletionRequest:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'ChatCompletion'; id: string; status: ChatCompletionStatus; response?: string | null }
    | { __typename: 'InternalError'; code: string; message: string };
};

export type CreateApiClientMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  clientName: Scalars['String']['input'];
}>;

export type CreateApiClientMutation = {
  __typename?: 'Mutation';
  createApiClient?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | {
        __typename: 'CreateApiClientResult';
        secret: string;
        client: { __typename?: 'ApiClient'; id: string; clientName: string };
      }
    | { __typename: 'InternalError'; code: string; message: string }
    | null;
};

export type DeleteApiClientMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  clientId: Scalars['ID']['input'];
}>;

export type DeleteApiClientMutation = {
  __typename?: 'Mutation';
  deleteApiClient?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'DeleteApiClientResult'; success: boolean }
    | { __typename: 'InternalError'; code: string; message: string }
    | null;
};

type OnError_BadRequestError_Fragment = { __typename: 'BadRequestError'; code: string; message: string };

type OnError_InternalError_Fragment = { __typename: 'InternalError'; code: string; message: string };

type OnError_NotFoundError_Fragment = { __typename: 'NotFoundError'; code: string; message: string };

export type OnErrorFragment =
  | OnError_BadRequestError_Fragment
  | OnError_InternalError_Fragment
  | OnError_NotFoundError_Fragment;

export type GetWorkspaceApiClientQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;

export type GetWorkspaceApiClientQuery = {
  __typename?: 'Query';
  workspace?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        apiClients?:
          | { __typename: 'BadRequestError'; code: string; message: string }
          | { __typename: 'InternalError'; code: string; message: string }
          | { __typename: 'NotFoundError'; code: string; message: string }
          | {
              __typename: 'WorkspaceApiClientsResult';
              nodes: Array<{ __typename?: 'ApiClient'; id: string; clientName: string }>;
            }
          | null;
      }
    | null;
};

export type CreateDataExportMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  dataExport: CreateDataExportInput;
}>;

export type CreateDataExportMutation = {
  __typename?: 'Mutation';
  createDataExport?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | {
        __typename: 'CreateDataExportResult';
        dataExport: { __typename?: 'DataExport'; id: string; exportBucketName: string };
      }
    | { __typename: 'InternalError'; code: string; message: string }
    | null;
};

export type DeleteDataExportMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  dataExportId: Scalars['String']['input'];
}>;

export type DeleteDataExportMutation = {
  __typename?: 'Mutation';
  deleteDataExport?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'DeleteDataExportResult'; success?: boolean | null }
    | { __typename: 'InternalError'; code: string; message: string }
    | null;
};

export type GetDataExportsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;

export type GetDataExportsQuery = {
  __typename?: 'Query';
  workspace?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        dataExports?:
          | { __typename: 'BadRequestError'; code: string; message: string }
          | { __typename: 'InternalError'; code: string; message: string }
          | { __typename: 'NotFoundError'; code: string; message: string }
          | {
              __typename: 'WorkspaceDataExportsResult';
              nodes: Array<{
                __typename?: 'DataExport';
                id: string;
                exportBucketName: string;
                exportBucketRegion: string;
                exportBucketKey?: string | null;
              }>;
            }
          | null;
      }
    | null;
};

export type TestDataExportConnectionMutationVariables = Exact<{
  bucketName: Scalars['String']['input'];
  bucketRegion?: InputMaybe<Scalars['String']['input']>;
}>;

export type TestDataExportConnectionMutation = {
  __typename?: 'Mutation';
  testDataExportConnection?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'TestDataExportConnectionResult'; success: boolean; errorMessage?: string | null }
    | null;
};

export type GetWorkspaceInvoicesQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;

export type GetWorkspaceInvoicesQuery = {
  __typename?: 'Query';
  workspace?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        invoices?:
          | { __typename: 'BadRequestError'; code: string; message: string }
          | { __typename: 'InternalError'; code: string; message: string }
          | { __typename: 'NotFoundError'; code: string; message: string }
          | {
              __typename: 'WorkspaceInvoicesConnection';
              nodes: Array<{
                __typename: 'WorkspaceInvoice';
                id: string;
                amount: string;
                billingPeriod: string;
                status: InvoiceStatus;
              }>;
            }
          | null;
      }
    | null;
};

type OnboardOnError_BadRequestError_Fragment = { __typename?: 'BadRequestError'; code: string; message: string };

type OnboardOnError_InternalError_Fragment = { __typename?: 'InternalError'; code: string; message: string };

type OnboardOnError_NotFoundError_Fragment = { __typename?: 'NotFoundError'; code: string; message: string };

export type OnboardOnErrorFragment =
  | OnboardOnError_BadRequestError_Fragment
  | OnboardOnError_InternalError_Fragment
  | OnboardOnError_NotFoundError_Fragment;

export type CreateCustomLinkMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  ik: Scalars['SafeString']['input'];
}>;

export type CreateCustomLinkMutation = {
  __typename?: 'Mutation';
  createCustomLink?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | {
        __typename: 'CreateCustomLinkResult';
        isIkReplay: boolean;
        link:
          | { __typename?: 'CustomLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename?: 'IncreaseLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename?: 'StripeLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename?: 'UnitLink'; id: string; entity: Entity; originalName: string; created: string };
      }
    | { __typename: 'InternalError'; code: string; message: string }
    | null;
};

export type GetLinkQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  linkId: Scalars['ID']['input'];
}>;

export type GetLinkQuery = {
  __typename?: 'Query';
  workspace?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        link?:
          | { __typename: 'BadRequestError'; code: string; message: string }
          | { __typename: 'CustomLink'; id: string; created: string; name: string }
          | { __typename: 'IncreaseLink'; increaseEnv: OnboardBnkDevEnv; id: string; created: string; name: string }
          | { __typename: 'InternalError'; code: string; message: string }
          | { __typename: 'NotFoundError'; code: string; message: string }
          | {
              __typename: 'StripeLink';
              stripeEnv: StripeEnv;
              rakLastUpdated?: string | null;
              id: string;
              created: string;
              name: string;
            }
          | {
              __typename: 'UnitLink';
              unitEnv: UnitEnv;
              orgTokenLastUpdated?: string | null;
              id: string;
              created: string;
              name: string;
            }
          | null;
      }
    | null;
};

export type LinkIncreaseMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
}>;

export type LinkIncreaseMutation = {
  __typename?: 'Mutation';
  linkBnkDev?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'LinkBnkDevResult'; link?: { __typename?: 'BnkDevTempLink'; id: string } | null }
    | null;
};

export type LinkUnitMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  unitOrgId: Scalars['ID']['input'];
  env: LinkUnitEnv;
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgToken: Scalars['String']['input'];
}>;

export type LinkUnitMutation = {
  __typename?: 'Mutation';
  linkUnit?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'LinkUnitResult';
        link:
          | { __typename?: 'CustomLink'; id: string; entity: Entity; created: string; originalName: string }
          | { __typename?: 'IncreaseLink'; id: string; entity: Entity; created: string; originalName: string }
          | { __typename?: 'StripeLink'; id: string; entity: Entity; created: string; originalName: string }
          | { __typename?: 'UnitLink'; id: string; entity: Entity; created: string; originalName: string };
      }
    | null;
};

export type OnboardIncreaseMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  tempLinkId: Scalars['ID']['input'];
  env: OnboardBnkDevEnv;
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnboardIncreaseMutation = {
  __typename?: 'Mutation';
  onboardBnkDev?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'OnboardBnkDevResult';
        link?:
          | { __typename: 'CustomLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'IncreaseLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'StripeLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'UnitLink'; id: string; entity: Entity; originalName: string; created: string }
          | null;
      }
    | null;
};

export type OnboardStripeMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  env: StripeEnv;
  disableSync?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type OnboardStripeMutation = {
  __typename?: 'Mutation';
  onboardStripe?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'OnboardStripeResult';
        link?:
          | { __typename: 'CustomLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'IncreaseLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'StripeLink'; id: string; entity: Entity; originalName: string; created: string }
          | { __typename: 'UnitLink'; id: string; entity: Entity; originalName: string; created: string }
          | null;
      }
    | null;
};

export type UpdateStripeLinkMutationVariables = Exact<{
  update: UpdateStripeLinkInput;
}>;

export type UpdateStripeLinkMutation = {
  __typename?: 'Mutation';
  updateStripeLink:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'UpdateStripeLinkResult';
        link?: {
          __typename?: 'StripeLink';
          id: string;
          created: string;
          stripeEnv: StripeEnv;
          rakLastUpdated?: string | null;
          name: string;
        } | null;
      };
};

export type UpdateUnitLinkMutationVariables = Exact<{
  update: UpdateUnitLinkInput;
}>;

export type UpdateUnitLinkMutation = {
  __typename?: 'Mutation';
  updateUnitLink:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'UpdateUnitLinkResult';
        link?: {
          __typename?: 'UnitLink';
          id: string;
          created: string;
          unitEnv: UnitEnv;
          orgTokenLastUpdated?: string | null;
          name: string;
        } | null;
      };
};

export type GetWorkspaceByIdQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;

export type GetWorkspaceByIdQuery = {
  __typename?: 'Query';
  workspace?:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | {
        __typename: 'Workspace';
        id: string;
        inviteEnabled?: boolean | null;
        inviteCode?: string | null;
        defaultCurrency: CurrencyCode;
        name: string;
      }
    | null;
};

export type GetLatestSchemaDraftQueryVariables = Exact<{
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
}>;

export type GetLatestSchemaDraftQuery = {
  __typename?: 'Query';
  schemaDrafts: {
    __typename?: 'SchemaDraftConnection';
    nodes: Array<{
      __typename: 'SchemaDraft';
      id: string;
      created: string;
      schemaKey: string;
      author: string;
      json: unknown;
    }>;
  };
};

export type GetSchemaDraftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
  created: Scalars['String']['input'];
}>;

export type GetSchemaDraftQuery = {
  __typename?: 'Query';
  schemaDraft:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | { __typename: 'NotFoundError'; code: string; message: string }
    | { __typename: 'SchemaDraft'; id: string; json: unknown; author: string; created: string; schemaKey: string };
};

export type ListSchemaDraftsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  schemaKey: Scalars['SafeString']['input'];
  workspaceId: Scalars['ID']['input'];
}>;

export type ListSchemaDraftsQuery = {
  __typename?: 'Query';
  schemaDrafts: {
    __typename?: 'SchemaDraftConnection';
    nodes: Array<{ __typename: 'SchemaDraft'; id: string; created: string; schemaKey: string; author: string }>;
    pageInfo: {
      __typename?: 'ReversiblePageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type SaveSchemaDraftMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  schemaKey: Scalars['SafeString']['input'];
  author: Scalars['String']['input'];
  schema: Scalars['JSON']['input'];
}>;

export type SaveSchemaDraftMutation = {
  __typename?: 'Mutation';
  saveSchemaDraft:
    | { __typename: 'BadRequestError'; code: string; message: string }
    | { __typename: 'InternalError'; code: string; message: string }
    | {
        __typename: 'SaveSchemaDraftResult';
        draft: { __typename?: 'SchemaDraft'; created: string; id: string; schemaKey: string };
      };
};

export const OnErrorFragmentDoc = gql`
    fragment onError on Error {
  __typename
  code
  message
}
    `;
export const OnboardOnErrorFragmentDoc = gql`
    fragment onboardOnError on Error {
  code
  message
}
    `;
export const GetChatCompletionDocument = gql`
    query GetChatCompletion($id: ID!, $workspaceId: ID!) {
  chatCompletion(id: $id, workspaceId: $workspaceId) {
    __typename
    ... on ChatCompletion {
      id
      status
      response
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useGetChatCompletionQuery(options: Omit<Urql.UseQueryArgs<GetChatCompletionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatCompletionQuery, GetChatCompletionQueryVariables>({
    query: GetChatCompletionDocument,
    ...options,
  });
}
export const SendChatCompletionRequestDocument = gql`
    mutation SendChatCompletionRequest($workspaceId: String!, $messages: [ChatCompletionMessage!]!) {
  sendChatCompletionRequest(workspaceId: $workspaceId, messages: $messages) {
    __typename
    ... on ChatCompletion {
      id
      status
      response
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useSendChatCompletionRequestMutation() {
  return Urql.useMutation<SendChatCompletionRequestMutation, SendChatCompletionRequestMutationVariables>(
    SendChatCompletionRequestDocument,
  );
}
export const CreateApiClientDocument = gql`
    mutation CreateApiClient($workspaceId: ID!, $clientName: String!) {
  createApiClient(workspaceId: $workspaceId, clientName: $clientName) {
    __typename
    ... on CreateApiClientResult {
      secret
      client {
        id
        clientName
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useCreateApiClientMutation() {
  return Urql.useMutation<CreateApiClientMutation, CreateApiClientMutationVariables>(CreateApiClientDocument);
}
export const DeleteApiClientDocument = gql`
    mutation DeleteApiClient($workspaceId: ID!, $clientId: ID!) {
  deleteApiClient(workspaceId: $workspaceId, clientId: $clientId) {
    __typename
    ... on DeleteApiClientResult {
      success
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useDeleteApiClientMutation() {
  return Urql.useMutation<DeleteApiClientMutation, DeleteApiClientMutationVariables>(DeleteApiClientDocument);
}
export const GetWorkspaceApiClientDocument = gql`
    query GetWorkspaceApiClient($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    __typename
    ... on Workspace {
      apiClients {
        __typename
        ... on WorkspaceApiClientsResult {
          nodes {
            id
            clientName
          }
        }
        ...onError
      }
    }
    ...onError
  }
}
    ${OnErrorFragmentDoc}`;

export function useGetWorkspaceApiClientQuery(
  options: Omit<Urql.UseQueryArgs<GetWorkspaceApiClientQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetWorkspaceApiClientQuery, GetWorkspaceApiClientQueryVariables>({
    query: GetWorkspaceApiClientDocument,
    ...options,
  });
}
export const CreateDataExportDocument = gql`
    mutation CreateDataExport($workspaceId: ID!, $dataExport: CreateDataExportInput!) {
  createDataExport(workspaceId: $workspaceId, dataExport: $dataExport) {
    __typename
    ... on CreateDataExportResult {
      dataExport {
        id
        exportBucketName
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useCreateDataExportMutation() {
  return Urql.useMutation<CreateDataExportMutation, CreateDataExportMutationVariables>(CreateDataExportDocument);
}
export const DeleteDataExportDocument = gql`
    mutation DeleteDataExport($workspaceId: ID!, $dataExportId: String!) {
  deleteDataExport(workspaceId: $workspaceId, dataExportId: $dataExportId) {
    __typename
    ... on DeleteDataExportResult {
      success
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useDeleteDataExportMutation() {
  return Urql.useMutation<DeleteDataExportMutation, DeleteDataExportMutationVariables>(DeleteDataExportDocument);
}
export const GetDataExportsDocument = gql`
    query GetDataExports($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    __typename
    ... on Workspace {
      dataExports {
        __typename
        ... on WorkspaceDataExportsResult {
          nodes {
            id
            exportBucketName
            exportBucketRegion
            exportBucketKey
          }
        }
        ...onError
      }
    }
    ...onError
  }
}
    ${OnErrorFragmentDoc}`;

export function useGetDataExportsQuery(options: Omit<Urql.UseQueryArgs<GetDataExportsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDataExportsQuery, GetDataExportsQueryVariables>({
    query: GetDataExportsDocument,
    ...options,
  });
}
export const TestDataExportConnectionDocument = gql`
    mutation TestDataExportConnection($bucketName: String!, $bucketRegion: String) {
  testDataExportConnection(bucketName: $bucketName, bucketRegion: $bucketRegion) {
    __typename
    ... on TestDataExportConnectionResult {
      success
      errorMessage
    }
    ...onError
  }
}
    ${OnErrorFragmentDoc}`;

export function useTestDataExportConnectionMutation() {
  return Urql.useMutation<TestDataExportConnectionMutation, TestDataExportConnectionMutationVariables>(
    TestDataExportConnectionDocument,
  );
}
export const GetWorkspaceInvoicesDocument = gql`
    query GetWorkspaceInvoices($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    ... on Workspace {
      __typename
      invoices {
        __typename
        ... on WorkspaceInvoicesConnection {
          nodes {
            __typename
            id
            amount
            billingPeriod
            status
          }
        }
        ...onError
      }
    }
    ...onError
  }
}
    ${OnErrorFragmentDoc}`;

export function useGetWorkspaceInvoicesQuery(
  options: Omit<Urql.UseQueryArgs<GetWorkspaceInvoicesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetWorkspaceInvoicesQuery, GetWorkspaceInvoicesQueryVariables>({
    query: GetWorkspaceInvoicesDocument,
    ...options,
  });
}
export const CreateCustomLinkDocument = gql`
    mutation CreateCustomLink($workspaceId: ID!, $name: String!, $ik: SafeString!) {
  createCustomLink(workspaceId: $workspaceId, name: $name, ik: $ik) {
    __typename
    ... on CreateCustomLinkResult {
      link {
        id
        entity
        originalName
        created
      }
      isIkReplay
    }
    ...onboardOnError
  }
}
    ${OnboardOnErrorFragmentDoc}`;

export function useCreateCustomLinkMutation() {
  return Urql.useMutation<CreateCustomLinkMutation, CreateCustomLinkMutationVariables>(CreateCustomLinkDocument);
}
export const GetLinkDocument = gql`
    query GetLink($workspaceId: ID!, $linkId: ID!) {
  workspace(id: $workspaceId) {
    __typename
    ... on Workspace {
      link(id: $linkId) {
        __typename
        ... on Link {
          id
          name: originalName
          created
          ... on IncreaseLink {
            increaseEnv
          }
          ... on UnitLink {
            unitEnv
            orgTokenLastUpdated
          }
          ... on StripeLink {
            stripeEnv
            rakLastUpdated
          }
        }
        ... on Error {
          code
          message
        }
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useGetLinkQuery(options: Omit<Urql.UseQueryArgs<GetLinkQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLinkQuery, GetLinkQueryVariables>({ query: GetLinkDocument, ...options });
}
export const LinkIncreaseDocument = gql`
    mutation LinkIncrease($workspaceId: ID!, $code: String!) {
  linkBnkDev(workspaceId: $workspaceId, code: $code) {
    __typename
    ... on LinkBnkDevResult {
      link {
        id
      }
    }
    ...onboardOnError
  }
}
    ${OnboardOnErrorFragmentDoc}`;

export function useLinkIncreaseMutation() {
  return Urql.useMutation<LinkIncreaseMutation, LinkIncreaseMutationVariables>(LinkIncreaseDocument);
}
export const LinkUnitDocument = gql`
    mutation LinkUnit($workspaceId: ID!, $unitOrgId: ID!, $env: LinkUnitEnv!, $disableSync: Boolean, $name: String!, $orgToken: String!) {
  linkUnit(
    workspaceId: $workspaceId
    unitOrgId: $unitOrgId
    env: $env
    disableSync: $disableSync
    name: $name
    orgToken: $orgToken
  ) {
    __typename
    ... on LinkUnitResult {
      link {
        id
        entity
        created
        originalName
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useLinkUnitMutation() {
  return Urql.useMutation<LinkUnitMutation, LinkUnitMutationVariables>(LinkUnitDocument);
}
export const OnboardIncreaseDocument = gql`
    mutation OnboardIncrease($workspaceId: ID!, $tempLinkId: ID!, $env: OnboardBnkDevEnv!, $disableSync: Boolean, $name: String) {
  onboardBnkDev(
    workspaceId: $workspaceId
    tempLinkId: $tempLinkId
    env: $env
    disableSync: $disableSync
    name: $name
  ) {
    __typename
    ... on OnboardBnkDevResult {
      link {
        __typename
        id
        entity
        originalName
        created
      }
    }
    ...onboardOnError
  }
}
    ${OnboardOnErrorFragmentDoc}`;

export function useOnboardIncreaseMutation() {
  return Urql.useMutation<OnboardIncreaseMutation, OnboardIncreaseMutationVariables>(OnboardIncreaseDocument);
}
export const OnboardStripeDocument = gql`
    mutation OnboardStripe($workspaceId: ID!, $code: String!, $env: StripeEnv!, $disableSync: Boolean, $name: String) {
  onboardStripe(
    workspaceId: $workspaceId
    code: $code
    env: $env
    disableSync: $disableSync
    name: $name
  ) {
    __typename
    ... on OnboardStripeResult {
      link {
        __typename
        id
        entity
        originalName
        created
      }
    }
    ...onboardOnError
  }
}
    ${OnboardOnErrorFragmentDoc}`;

export function useOnboardStripeMutation() {
  return Urql.useMutation<OnboardStripeMutation, OnboardStripeMutationVariables>(OnboardStripeDocument);
}
export const UpdateStripeLinkDocument = gql`
    mutation UpdateStripeLink($update: UpdateStripeLinkInput!) {
  updateStripeLink(input: $update) {
    __typename
    ... on UpdateStripeLinkResult {
      link {
        id
        name: originalName
        created
        stripeEnv
        rakLastUpdated
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useUpdateStripeLinkMutation() {
  return Urql.useMutation<UpdateStripeLinkMutation, UpdateStripeLinkMutationVariables>(UpdateStripeLinkDocument);
}
export const UpdateUnitLinkDocument = gql`
    mutation UpdateUnitLink($update: UpdateUnitLinkInput!) {
  updateUnitLink(input: $update) {
    __typename
    ... on UpdateUnitLinkResult {
      link {
        id
        name: originalName
        created
        unitEnv
        orgTokenLastUpdated
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useUpdateUnitLinkMutation() {
  return Urql.useMutation<UpdateUnitLinkMutation, UpdateUnitLinkMutationVariables>(UpdateUnitLinkDocument);
}
export const GetWorkspaceByIdDocument = gql`
    query GetWorkspaceById($workspaceId: ID!) {
  workspace(id: $workspaceId) {
    __typename
    ... on Workspace {
      __typename
      id
      inviteEnabled
      inviteCode
      defaultCurrency
      name
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useGetWorkspaceByIdQuery(options: Omit<Urql.UseQueryArgs<GetWorkspaceByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkspaceByIdQuery, GetWorkspaceByIdQueryVariables>({
    query: GetWorkspaceByIdDocument,
    ...options,
  });
}
export const GetLatestSchemaDraftDocument = gql`
    query GetLatestSchemaDraft($schemaKey: SafeString!, $workspaceId: ID!) {
  schemaDrafts(workspaceId: $workspaceId, schemaKey: $schemaKey, last: 1) {
    nodes {
      __typename
      id
      created
      schemaKey
      author
      json
    }
  }
}
    `;

export function useGetLatestSchemaDraftQuery(
  options: Omit<Urql.UseQueryArgs<GetLatestSchemaDraftQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetLatestSchemaDraftQuery, GetLatestSchemaDraftQueryVariables>({
    query: GetLatestSchemaDraftDocument,
    ...options,
  });
}
export const GetSchemaDraftDocument = gql`
    query GetSchemaDraft($id: ID!, $schemaKey: SafeString!, $workspaceId: ID!, $created: String!) {
  schemaDraft(
    id: $id
    schemaKey: $schemaKey
    workspaceId: $workspaceId
    created: $created
  ) {
    __typename
    ... on SchemaDraft {
      id
      json
      author
      created
      schemaKey
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useGetSchemaDraftQuery(options: Omit<Urql.UseQueryArgs<GetSchemaDraftQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSchemaDraftQuery, GetSchemaDraftQueryVariables>({
    query: GetSchemaDraftDocument,
    ...options,
  });
}
export const ListSchemaDraftsDocument = gql`
    query ListSchemaDrafts($after: String, $before: String, $first: Int, $last: Int, $schemaKey: SafeString!, $workspaceId: ID!) {
  schemaDrafts(
    workspaceId: $workspaceId
    schemaKey: $schemaKey
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    nodes {
      __typename
      id
      created
      schemaKey
      author
    }
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
      startCursor
    }
  }
}
    `;

export function useListSchemaDraftsQuery(options: Omit<Urql.UseQueryArgs<ListSchemaDraftsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListSchemaDraftsQuery, ListSchemaDraftsQueryVariables>({
    query: ListSchemaDraftsDocument,
    ...options,
  });
}
export const SaveSchemaDraftDocument = gql`
    mutation SaveSchemaDraft($workspaceId: ID!, $schemaKey: SafeString!, $author: String!, $schema: JSON!) {
  saveSchemaDraft(
    workspaceId: $workspaceId
    schemaKey: $schemaKey
    author: $author
    schema: $schema
  ) {
    __typename
    ... on SaveSchemaDraftResult {
      draft {
        created
        id
        schemaKey
      }
    }
    ... on Error {
      code
      message
    }
  }
}
    `;

export function useSaveSchemaDraftMutation() {
  return Urql.useMutation<SaveSchemaDraftMutation, SaveSchemaDraftMutationVariables>(SaveSchemaDraftDocument);
}
