import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { sizeToSvgWidth } from '../../utils';

export const AddLink: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  'data-testid': testId = 'linkbadge',
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12"
      height="12"
      transform="translate(0.340088)"
      fill="#0d0d0d"
    />
    <defs>
      <clipPath id="clip0_5219_41825">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.234375)"
        />
      </clipPath>
    </defs>
  </svg>
);
