import defaultCopy from 'copy-to-clipboard';
import { useCallback, useEffect, useRef, useState } from 'react';

export type InlineCopyProps = {
  duration?: number;
  textToCopy: string;
  stopEventPropagationAndDefault?: boolean;
  onClick?: (arg0: string) => boolean;
  onCopy?: VoidFunction;
  onCopySuccess?: VoidFunction;
  onCopyError: VoidFunction;
};

export function useInlineCopy({
  duration = 1000,
  textToCopy,
  stopEventPropagationAndDefault = false,
  onClick = defaultCopy,
  onCopy = () => {},
  onCopySuccess = () => {},
  onCopyError,
}: InlineCopyProps) {
  const [isCopied, setIsCopied] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  const handler = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (e && stopEventPropagationAndDefault) {
        e.stopPropagation();
        e.preventDefault();
      }
      if (!isCopied) {
        const success = onClick(textToCopy);
        if (!success) {
          onCopyError();
          return;
        }
        setIsCopied(true);
        onCopy();
        timerRef.current = setTimeout(() => {
          setIsCopied(false);
          onCopySuccess();
        }, duration);
      }
    },
    [
      stopEventPropagationAndDefault,
      isCopied,
      onClick,
      textToCopy,
      onCopySuccess,
      duration,
      onCopyError,
      onCopy,
    ]
  );

  return {
    isCopied,
    handler,
  };
}
