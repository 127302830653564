import React from 'react';

import { Icon } from '@fragment/ui/src/components/Icon';

export const WorkspaceNotFound = (): JSX.Element => (
  <div className="p-8 flex flex-col items-start gap-f8 text-fxl">
    <span>Workspace Not Found</span>
    <div className="flex flex-col items-start">
      <a className="text-main-500" href="/">
        Back to Dashboard{' '}
        <Icon type="right" size="text-fxl" primaryColor="gray" />
      </a>
    </div>
  </div>
);
