import type { DefaultParams, Params } from 'wouter';

import type { KeyedRequired } from '@fragment/types';

export const decodeParams = <T extends DefaultParams>(
  params: Params<T>
): Params<T> =>
  Object.keys(params).reduce((acc, paramName) => {
    acc[paramName] = decodeURIComponent(params[paramName]);
    return acc;
  }, {} as Record<string, string>) as T;

export const decodeRouteAndRender = <T extends DefaultParams>(
  params: T,
  render: (value: T) => JSX.Element
) => {
  const decodedParams = Object.entries(params).reduce(
    (acc: DefaultParams, [param, value]) => {
      acc[param] = decodeURIComponent(value);
      return acc;
    },
    {}
  );
  return render(decodedParams as T);
};

type MakePathOptions = {
  trailingSlash?: boolean;
  searchParams?: URLSearchParams;
  /**
   * If true, the path will be prefixed with a tilde (~) to indicate that it is an absoltue route.
   * Otherwise, the path will be matched against the current router.
   * Use this if you want to route to a path that's under a different router.
   */
  absolute?: boolean;
};

const defaultMakePathOptions: KeyedRequired<
  MakePathOptions,
  keyof MakePathOptions
> = {
  trailingSlash: false,
  searchParams: new URLSearchParams(),
  absolute: false,
};

export const makePath = (components: string[], opts?: MakePathOptions) => {
  const options = {
    ...defaultMakePathOptions,
    ...(opts ?? {}),
  };

  const encoded = components.map(encodeURIComponent).join('/');
  const hasSearchParams = Array.from(options.searchParams.keys()).length > 0;
  const path = options.trailingSlash ? `/${encoded}/` : `/${encoded}`;
  const url = `${path}${
    hasSearchParams ? `?${options.searchParams.toString()}` : ''
  }`;
  return options.absolute ? `~${url}` : url;
};
