import type { FC, PropsWithChildren } from 'react';
import { GlobalApiAuthenticationFailed } from 'components/AuthenticationFailed';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';

export type GlobalApiAccessTokenContextValue = {
  globalApiAccessToken?: string;
  logout: Auth0ContextInterface['logout'];
};
const GlobalApiAccessTokenContext =
  createContext<GlobalApiAccessTokenContextValue | null>(null);
const { Provider } = GlobalApiAccessTokenContext;

export const GlobalApiAccessTokenProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const [accessToken, setAccessToken] = useState<string>();
  const [didAccessTokenFetchFail, setDidAccessTokenFetchFail] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchAccessTokens = async () => {
      if (isAuthenticated && user?.sub) {
        try {
          const globalApiAccessToken = await getAccessTokenSilently();
          setAccessToken(globalApiAccessToken);
        } catch (error) {
          setDidAccessTokenFetchFail(true);
        }
      }
    };

    fetchAccessTokens();
  }, [getAccessTokenSilently, isAuthenticated, user?.sub]);
  const contextValue: GlobalApiAccessTokenContextValue = useMemo(
    () => ({
      logout,
      globalApiAccessToken: accessToken,
    }),
    [accessToken, logout]
  );
  if (didAccessTokenFetchFail) {
    return <GlobalApiAuthenticationFailed />;
  }

  return <Provider value={contextValue}>{children}</Provider>;
};

export const useGlobalApiAccessToken = () =>
  useContext(GlobalApiAccessTokenContext);
