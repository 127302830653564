import { DefaultLoginRedirect } from 'components/login/DefaultLoginRedirect';
import { LoggedInPage } from 'components/login/LoggedInPage';
import { DefaultRedirect } from 'components/pages/DefaultRedirect';
import { WorkspaceSettingsTabs } from 'components/pages/SchemasV2/WorkspaceSettings/WorkspaceSettingsModal';
import React, { lazy } from 'react';
import { putLocalStorage } from 'utils/localStorage';
import { makePath } from 'utils/routes';
import { Route, Switch } from 'wouter';
import { USER_LOGIN_REDIRECT } from 'wrappers/AuthProvider';
import { ProtectedRoute } from 'wrappers/ProtectedRoute';

import { DecodedParamsRoute } from './DecodedParamsRoute';

const NewWorkspacePage = lazy(async () => ({
  default: (await import('components/pages/NewWorkspacePage')).NewWorkspacePage,
}));
const UserInviteLoginPage = lazy(async () => ({
  default: (await import('components/pages/UserInviteLoginPage'))
    .UserInviteLoginPage,
}));
const AcceptInvitePage = lazy(async () => ({
  default: (await import('components/pages/AcceptInvitePage')).AcceptInvitePage,
}));

const WorkspaceInviteLoginPage = lazy(async () => ({
  default: (await import('components/pages/WorkspaceInviteLoginPage'))
    .WorkspaceInviteLoginPage,
}));
const IncreaseWorkspaceURLRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/IncreaseWorkspaceURLRedirect')
  ).IncreaseWorkspaceURLRedirect,
}));
const IncreaseOnboardingRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/OnboardIncreaseRedirect')
  ).OnboardIncreaseRedirect,
}));
const StripeWorkspaceURLRedirect = lazy(async () => ({
  default: (
    await import('components/routing/redirects/StripeWorkspaceURLRedirect')
  ).StripeWorkspaceURLRedirect,
}));
const StripeOnboardingRedirect = lazy(async () => ({
  default: (await import('components/routing/redirects/OnboardStripeRedirect'))
    .OnboardStripeRedirect,
}));

const FragmentIncIncomeStatementPage = lazy(async () => ({
  default: (await import('components/pages/FragmentIncIncomeStatementPage'))
    .FragmentIncIncomeStatementPage,
}));
const FragmentIncBalanceSheetPage = lazy(async () => ({
  default: (await import('components/pages/FragmentIncBalanceSheetPage'))
    .FragmentIncBalanceSheetPage,
}));
const FragmentIncGLDetailPage = lazy(async () => ({
  default: (await import('components/pages/FragmentIncGLDetailPage'))
    .FragmentIncGLDetailPage,
}));

const DashboardRouter = lazy(async () => ({
  default: (await import('components/routing/DashboardRouter')).DashboardRouter,
}));

export const Routes = () => (
  <Switch>
    <Route path="/login">
      <DefaultLoginRedirect />
    </Route>
    <Route path={USER_LOGIN_REDIRECT}>
      <LoggedInPage />
    </Route>

    <Route path="/invite/:inviteCode" component={UserInviteLoginPage} />

    <Route
      path="/invite/user-new-workspace/:inviteCode"
      component={NewWorkspacePage}
    />

    <Route path="/add-new-workspace" component={NewWorkspacePage} />

    <DecodedParamsRoute
      path="/w/:workspaceId/invite/:inviteCode"
      component={WorkspaceInviteLoginPage}
    />

    <DecodedParamsRoute
      path="/w/:workspaceId/invite/:inviteCode/accept"
      component={AcceptInvitePage}
    />

    <Route path="/oauth/increase/redirect">
      <IncreaseWorkspaceURLRedirect />
    </Route>

    <ProtectedRoute path="/w/:workspaceId/oauth/increase">
      <IncreaseOnboardingRedirect />
    </ProtectedRoute>

    <Route path="/oauth/stripe/redirect">
      <StripeWorkspaceURLRedirect />
    </Route>

    <ProtectedRoute path="/w/:workspaceId/oauth/stripe">
      <StripeOnboardingRedirect />
    </ProtectedRoute>
    <ProtectedRoute path="/w/:workspaceId/:rest*">
      {({ workspaceId }) => (
        <DashboardRouter
          workspaceId={workspaceId}
          base={makePath(['w', workspaceId])}
        />
      )}
    </ProtectedRoute>

    <Route path="/garbage/income-statement">
      <FragmentIncIncomeStatementPage />
    </Route>

    <Route path="/garbage/balance-sheet">
      <FragmentIncBalanceSheetPage />
    </Route>

    <Route path="/garbage/gl-detail-report">
      <FragmentIncGLDetailPage />
    </Route>

    {/* Don't love this use of render props but not sure if it's worth it to move */}
    <Route path="/go/:slug*">
      {({ slug }) => {
        const redirect =
          {
            schemas: 'schemas',
            ledgers: 'ledgers',
            explorer: 'explorer',
            links: 'links',
            ...WorkspaceSettingsTabs.reduce((acc, settingsTab) => {
              acc[`s/${settingsTab}`] = `projects/settings/${settingsTab}`;
              return acc;
            }, {} as Record<string, string>),
          }[slug] ?? slug;
        putLocalStorage('post_login_redirect', redirect);
        return <DefaultRedirect />;
      }}
    </Route>

    <Route>
      <DefaultRedirect />
    </Route>
  </Switch>
);
