import type { ReactNode } from 'react';
import { useContextSafely } from 'hooks/useContextSafely';
import { useQueryParam } from 'hooks/useQueryParams';
import React, {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'wouter';

import { LoadingText } from '@fragment/ui/src/components/LoadingText';
import { Modal } from '@fragment/ui/src/components/Modal/Modal';

import { usePodApiAccessTokenContext } from './PodApiAccessTokenProvider';
import { useWorkspace } from './WorkspaceProvider';

const ApiExplorer = lazy(async () => ({
  default: (await import('../components/explorer/graphiql')).ApiExplorer,
}));

const ApiExplorerContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({ isOpen: false, setIsOpen: () => {} });

export const ApiExplorerProvider = ({ children }: { children: ReactNode }) => {
  const { workspace } = useWorkspace();
  const { customerApiToken } = usePodApiAccessTokenContext();
  const [explorerState, setExplorerState] = useQueryParam('apiExplorer');
  const [, setQuery] = useQueryParam('query');
  const setIsApiExplorerOpen = useCallback(
    (isOpen: boolean) => {
      setExplorerState(isOpen ? 'open' : undefined);
      // When the explorer is closed, clear any preset query
      if (!isOpen) {
        setQuery(undefined);
      }
    },
    [setExplorerState, setQuery]
  );
  const isApiExplorerOpen = explorerState === 'open';

  useHotkeys(
    '`',
    () => {
      if (isApiExplorerOpen) {
        setIsApiExplorerOpen(false);
        return;
      }
      const existingModal = document.querySelector(
        'div.fragment-modal-content'
      );
      if (existingModal) {
        // A modal other than the API explorer is open, don't open a new modal on top.
        return;
      }
      setIsApiExplorerOpen(!isApiExplorerOpen);
    },
    [isApiExplorerOpen, setIsApiExplorerOpen]
  );
  const [path] = useLocation();
  const headers = useMemo(
    () => ({
      'x-fragment-workspace': workspace?.id ?? '',
      'x-fragment-current-path': path,
      'x-fragment-client': 'dashboard',
    }),
    [workspace?.id, path]
  );
  const closeModal = useCallback(
    () => setIsApiExplorerOpen(false),
    [setIsApiExplorerOpen]
  );
  const contextValue = useMemo(
    () => ({
      isOpen: isApiExplorerOpen,
      setIsOpen: setIsApiExplorerOpen,
    }),
    [isApiExplorerOpen, setIsApiExplorerOpen]
  );
  return (
    <ApiExplorerContext.Provider value={contextValue}>
      {children}
      {isApiExplorerOpen &&
        workspace &&
        customerApiToken &&
        createPortal(
          <Modal
            onClose={closeModal}
            noPadding
            body={
              <div className="w-[90vw] h-[85vh]">
                <Suspense
                  fallback={
                    <div className="text-main-500 p-f2">
                      <LoadingText text="Loading API Explorer" />
                    </div>
                  }
                >
                  <ApiExplorer
                    token={customerApiToken}
                    headers={headers}
                    endpoint={workspace.pod.dashboardCustomerApiUrl}
                    storage={window.localStorage}
                  />
                </Suspense>
              </div>
            }
          />,
          document.body
        )}
    </ApiExplorerContext.Provider>
  );
};

export const useApiExplorerContext = () => useContextSafely(ApiExplorerContext);
