import clsx from 'clsx';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { useLocation, useRoute } from 'wouter';

import { List, ListItem, Marker } from '@fragment/ui/src/components/List';
import { LoadingText } from '@fragment/ui/src/components/LoadingText';
import { Modal } from '@fragment/ui/src/components/Modal/Modal';

import { useBillingTabEnabled } from '../../../../hooks/useBillingTabEnabled';
import { useUserInfo } from '../../../../hooks/useUserInfo';
import { LoadingTextSuspense } from '../../../LoadingTextSuspense/LoadingTextSuspense';
import { ApiClientsSettingsForm } from './ApiClientsSettingsForm';
import { BillingForm } from './BillingForm';
import { DataExportsSettingsForm } from './DataExportsSettingsForm';
import { MembersList } from './MembersList';
import { Preferences } from './Preferences';
import { Profile } from './Profile';
import { UpdateWorkspaceSettingsForm } from './UpdateWorkspaceSettingsForm';

export const WORKSPACE_SETTINGS_MODAL_URL_PREFIX = '/projects/settings';

// Note: These tab slugs are user-facing, since we construct the /go/ URLs
// from these values. Choose them wisely.
export const WorkspaceSettingsTabs = [
  'workspace',
  'api-clients',
  'members',
  'data-exports',
  'billing',
] as const;
export type WorkspaceSettingsTabsType = (typeof WorkspaceSettingsTabs)[number];

const UserSettingsTabs = ['Profile', 'Preferences'] as const;
type UserSettingsTabsType = (typeof UserSettingsTabs)[number];

type WorkspaceSettingsModalProps = {
  defaultTab?: WorkspaceSettingsTabsType;
  handleClose: VoidFunction;
};

export const WorkspaceSettingsModal = ({
  defaultTab = 'workspace',
  handleClose,
}: WorkspaceSettingsModalProps) => {
  const { refreshUserState } = useUserInfo();
  const [activeTab, setActiveTab] = useState<
    WorkspaceSettingsTabsType | UserSettingsTabsType
  >(defaultTab);
  const [pathMatch] = useRoute(`${WORKSPACE_SETTINGS_MODAL_URL_PREFIX}/:tab*`);
  const [, navigate] = useLocation();
  const billingTabEnabled = useBillingTabEnabled();
  const modalContent = useMemo(
    () =>
      match(activeTab)
        .with('workspace', () => (
          <LoadingTextSuspense text="Loading">
            <div>
              <div className="pb-f4">
                <span>General</span>
              </div>
              <UpdateWorkspaceSettingsForm
                onWorkspaceUpdate={refreshUserState}
              />
            </div>
          </LoadingTextSuspense>
        ))
        .with('api-clients', () => <ApiClientsSettingsForm />)
        .with('members', () => <MembersList />)
        .with('data-exports', () => <DataExportsSettingsForm />)
        .with('billing', () => <BillingForm />)
        .with('Profile', () => <Profile />)
        .with('Preferences', () => <Preferences />)
        .exhaustive(),
    [activeTab, refreshUserState]
  );

  const onClose = useCallback(() => {
    if (pathMatch) {
      // If this was opened via the URL, navigate back to the projects
      // page without replacing history so browser "Back" works.
      navigate('/projects');
    }
    handleClose();
  }, [navigate, pathMatch, handleClose]);
  return (
    <Modal
      onClose={onClose}
      body={
        <div className="flex w-[calc(100vw-8ch)] max-w-[100ch] bg-canvas">
          <aside className="w-[25%] max-w-[20ch] bg-canvas border-r-[1px] border-main-200">
            <div className="text-main pb-f4">Settings</div>
            <div>
              <span className="text-main-500">Workspace</span>
            </div>
            <List type="unordered">
              {WorkspaceSettingsTabs.map((tab, i) => (
                <ListItem
                  key={tab}
                  group
                  // startTransition here to prevent suspense from triggering
                  onClick={
                    !billingTabEnabled && tab === 'billing'
                      ? undefined
                      : () => setActiveTab(tab)
                  }
                  extraClassNames={clsx(
                    tab === activeTab && 'bg-negative',
                    'hover:cursor-pointer',
                    !billingTabEnabled && tab === 'billing'
                      ? 'hover:cursor-disabled text-main-500'
                      : 'hover:bg-negative'
                  )}
                >
                  <div className="flex flex-row space-x-[1ch]">
                    <Marker
                      type="numeric"
                      ordinal={i}
                      color="group-hover:text-main text-main-500"
                    />
                    <span>
                      {match(tab)
                        .with('workspace', () => 'General')
                        .with('api-clients', () => 'API Clients')
                        .with('members', () => 'Members')
                        .with('data-exports', () => 'Data Exports')
                        .with('billing', () => 'Billing')
                        .exhaustive()}
                    </span>
                  </div>
                </ListItem>
              ))}
            </List>
            <div className="pt-f2">
              <span className="text-main-500">User</span>
            </div>
            <List type="unordered">
              {UserSettingsTabs.map((tab, i) => (
                <ListItem
                  key={tab}
                  group
                  // startTransition here to prevent suspense from triggering
                  onClick={() => setActiveTab(tab)}
                  extraClassNames={clsx(
                    tab === activeTab && 'bg-negative',
                    'space-x-[1ch]',
                    'hover:bg-negative hover:cursor-pointer'
                  )}
                >
                  <Marker
                    type="numeric"
                    ordinal={i}
                    color="group-hover:text-main text-main-500"
                  />
                  <span>{tab}</span>
                </ListItem>
              ))}
            </List>
          </aside>
          <section className="w-full min-h-f40 pl-f2 bg-canvas">
            <Suspense fallback={<LoadingText text="Loading" />}>
              {modalContent}
            </Suspense>
          </section>
        </div>
      }
    />
  );
};
