import { useFlags } from 'flagsmith/react';
import { type DashboardFlags, localFlagValues } from 'localFlagValues';
import { FragmentEnv } from 'utils/env';
import { getLocalStorage } from 'utils/localStorage';

// To override a flag, set a value in `localStorage` with the key `fragment-local-storage:flagsmith_overrides`.
// and the value should be a JSON object with the flag names as keys and { enabled: <boolean> }  as values.
// localStorage.setItem('fragment-local-storage:flagsmith_overrides', JSON.stringify({
//   'billing-tab-enabled': { enabled: false },
//   'unit-integration-enabled': { enabled: false },
//   'fragment-inc-dashboard-pages': { enabled: false },
// }));
export function useFlagsWithOverrides(
  names: readonly string[],
  traits?: string[]
) {
  const flagsmithFlagsAndTraits = useFlags(names, traits);
  const overrides = getLocalStorage('flagsmith_overrides') ?? {};
  return Object.fromEntries(
    [...names, ...(traits ?? [])].map((key) => {
      const flagValue =
        window.Cypress || FragmentEnv.flagsmith.shouldMock
          ? localFlagValues[key as DashboardFlags]
          : flagsmithFlagsAndTraits[key];
      return [key, overrides[key] ?? flagValue];
    })
  ) as ReturnType<typeof useFlags>;
}
