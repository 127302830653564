import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const AssetLine = ({
  'data-testid': testId,
  width = sizeToSvgWidth.fnormal,
  backgroundColorHexCode = colorToHexCode.white,
  primaryColorHexCode = colorToHexCode['asset-green'],
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    data-testid={testId}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.573975"
      y="0.5"
      width="12"
      height="12"
      rx="6"
      fill={backgroundColorHexCode}
    />
    <path
      d="M0.573975 6.5C0.573975 3.18629 3.26027 0.5 6.57397 0.5V6.5H0.573975Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
