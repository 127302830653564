import React from 'react';
import { type Route } from 'wouter';

import { decodeParams } from '../../utils/routes';
import { ParamsTransformer } from './ParamsTransformer';

export const DecodedParamsRoute: typeof Route = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ParamsTransformer {...props} transformer={decodeParams} />
);
