import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@fragment/ui/src/components/Button/BaseButton/Button';

export const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button
      type="button"
      display="flex"
      color="text-main"
      background="bg-main-200 hover:bg-negative"
      fontWeight="font-light"
      w="w-full"
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Logout
    </Button>
  );
};
