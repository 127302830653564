import React, { type FC, type ReactNode, Suspense } from 'react';

import { LoadingText } from '@fragment/ui/src/components/LoadingText';

type Props = {
  text: string;
  children: ReactNode;
};

export const LoadingTextSuspense: FC<Props> = ({ children, text }) => (
  <Suspense fallback={<LoadingText text={text} />}>{children}</Suspense>
);
