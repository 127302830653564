import type { OnErrorFragment } from 'hooks/internalApi';
import type { CombinedError } from 'urql';

export type GraphqlObject = {
  __typename?: string;
};

type ApiError = OnErrorFragment;

const KNOWN_ERROR_CODES = {
  ik_request_body_mismatch: 'This IK has already been used.',
};

export type ValueOrError<T extends GraphqlObject> = T | ApiError;
export const UNKNOWN_ERROR_MESSAGE = 'A system error occurred.';

export function isGraphQLError<T extends GraphqlObject>(
  graphqlResponseData: ValueOrError<T>
): graphqlResponseData is ApiError {
  if (
    graphqlResponseData.__typename === 'NotFoundError' ||
    graphqlResponseData.__typename === 'BadRequestError' ||
    graphqlResponseData.__typename === 'InternalError'
  ) {
    return true;
  }
  return false;
}

const refinerRegex = /\s*(.+?)\s*(\([a-zA-Z0-9]+\))?$/;
/**
 * Extracts an error message from `value`, if it is an error.
 * @param value An error or other graphql object
 * @returns An error messsage if the value given is an error, or undefined.
 */
export function extractErrorMessage<T extends GraphqlObject>(
  value: ValueOrError<T>
): string | undefined {
  if (!isGraphQLError(value)) {
    return undefined;
  }
  if (value.code in KNOWN_ERROR_CODES) {
    return KNOWN_ERROR_CODES[value.code as keyof typeof KNOWN_ERROR_CODES];
  }
  const match = value.message.match(refinerRegex);
  if (!match) {
    return UNKNOWN_ERROR_MESSAGE;
  }
  return match[1];
}

/**
 * Utility for error handling for graphql responses/errors.
 * Usage:
 * {data, error} = executeGraphqlQuery(variables);
 * {value, errorMessage} = getValueOrError(data, error);
 * @param data a graphql reponse that may be either an error or a concrete value. Usually you want to pass `data?.operationName` for this.
 * @param error an optional request error from URQL.
 * @returns Either a strongly-typed value, if the request was successful, or an error message, if it was not.
 */
export function getResultOrError<T extends GraphqlObject>(
  data: ValueOrError<T> | null | undefined,
  error?: CombinedError
):
  | { isError: true; errorMessage: string; result?: undefined }
  | { isError: false; result: T; errorMessage?: undefined } {
  if (error || !data) {
    return {
      isError: true,
      errorMessage: error?.message || UNKNOWN_ERROR_MESSAGE,
    };
  }
  if (isGraphQLError(data)) {
    const errorMessage = extractErrorMessage(data);
    return {
      isError: true,
      errorMessage: errorMessage || UNKNOWN_ERROR_MESSAGE,
    };
  }
  return { isError: false, result: data };
}
