import { LoginView } from 'components/login/LoginView';
import { useUserInfo } from 'hooks/useUserInfo';
import React, { useEffect, useState } from 'react';
import { getLocalStorage, removeLocalStorage } from 'utils/localStorage';
import { Redirect } from 'wouter';
import { useAuth0 } from '@auth0/auth0-react';

export const LoggedInRedirect: typeof Redirect = (props) => {
  useEffect(() => {
    removeLocalStorage('user_redirect_href');
  }, []);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Redirect {...props} />;
};

export const LoggedInPage = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const [storedUserRedirect] = useState(getLocalStorage('user_redirect_href'));
  const userInfo = useUserInfo();
  const loading =
    isLoading || (isAuthenticated && userInfo.type === 'not_logged_in');
  if (loading) {
    return <LoginView loading />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" replace />;
  }

  const guestMode: boolean = storedUserRedirect?.guest ?? false;
  if ((userInfo.data || guestMode) && storedUserRedirect) {
    return <LoggedInRedirect to={storedUserRedirect.path} replace />;
  }
  if (userInfo.data || guestMode) {
    return <Redirect to="/" replace />;
  }

  if (error) {
    return <LoginView errorMessage="Authentication failed" />;
  }

  if (userInfo.type === 'user_not_found') {
    return <LoginView errorMessage="Email not found" />;
  }
  if (userInfo.type === 'server_error') {
    return <LoginView errorMessage="Internal error" />;
  }

  return null;
};
