import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Sidebar: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  primaryColorHexCode = colorToHexCode.black,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49573 1.37207H7.21714V8.62775H5.49573L5.49573 1.37207ZM4.49573 0.37207H5.49573H8.27942V9.62775H5.49573H4.49573H0.25V0.37207H4.49573ZM4.49573 8.62775H1.25V1.37207H4.49573L4.49573 8.62775Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
