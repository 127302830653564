import clsx from 'clsx';
import React, { type PropsWithChildren } from 'react';

import type { TextSizeType } from '../../utils';

type ColSpanValues = '1' | '2' | '3' | '4' | '5' | '6' | '7';

export type Props = {
  justify?: 'left' | 'right';
  colspan?: `col-span-${ColSpanValues}`;
  textSize?: TextSizeType;
};

export const Cell = ({
  children,
  justify,
  colspan,
  textSize,
}: PropsWithChildren<Props>): JSX.Element => (
  <div
    role="cell"
    className={clsx(
      'inline',
      justify === 'right' ? 'text-right' : 'text-left',
      colspan,
      'whitespace-nowrap overflow-x-scroll scrollbar-hide grid items-center'
    )}
  >
    {children}
  </div>
);
Cell.displayName = 'NavTable.Cell';
