import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { colorToHexCode } from '../../utils';

export const LineGeneral: FC<BaseSvgProps> = ({
  primaryColorHexCode = colorToHexCode.gray500,
}) => (
  <svg
    width="13"
    height="13"
    className="shrink-0"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.699219"
      y="0.5"
      width="12"
      height="12"
      rx="6"
      fill={primaryColorHexCode}
    />
    <path
      d="M0.699219 6.5C0.699219 3.18629 3.38551 0.5 6.69922 0.5V6.5H0.699219Z"
      fill="#E6E6E6"
    />
  </svg>
);
