import { useEffect, useMemo, useRef, useState } from 'react';

export type UseTickerOptions = {
  startAt?: number;
  onDone?: () => void;
};

type TickerIntervalArg = number | ((arg0: number) => number);

export const useTicker = (
  interval: TickerIntervalArg,
  stopAtTick: number,
  tickerOpts: UseTickerOptions | undefined = undefined
) => {
  const [config, setConfig] = useState<{
    interval: TickerIntervalArg;
    stopAtTick: number;
    startAt: number;
  }>({ interval, stopAtTick, startAt: tickerOpts?.startAt ?? 0 });

  const intervalFn = useMemo<(arg0: number) => number>(
    () =>
      typeof config.interval === 'number'
        ? () => config.interval as number
        : config.interval,
    [config.interval]
  );

  const [done, setDone] = useState(false);
  const [tick, setTick] = useState(config.startAt);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const startAt = tickerOpts?.startAt ?? 0;
    setConfig({ interval, stopAtTick, startAt });
    setTick(startAt);
    setDone(false);
  }, [interval, stopAtTick, tickerOpts]);

  useEffect(() => {
    if (tick === config.stopAtTick) {
      setDone(true);
      tickerOpts?.onDone?.();
    }
  }, [tick, config.stopAtTick, tickerOpts]);

  useEffect(() => {
    if (!done) {
      timerRef.current = setTimeout(
        () =>
          setTick((previousTick) =>
            Math.min(previousTick + 1, config.stopAtTick)
          ),
        intervalFn(tick)
      );
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [tick, config.stopAtTick, intervalFn, done]);

  return { tick, done };
};

export const useLoopingTicker = (delay: number, max: number, active = true) => {
  const [tick, setTick] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (active) {
        setTick((current) => (current === max ? 0 : current + 1));
      }
    }, delay);
    return () => clearInterval(interval);
  }, [delay, active, max]);

  return tick;
};
