import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, sizeToSvgWidth } from '../../utils';

export const Empty: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  backgroundColorHexCode = 'transparent',
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <circle
      cx="6"
      cy="6"
      r="5"
      stroke="#CCCCCC"
      strokeWidth="1"
      fill={backgroundColorHexCode}
    />
  </svg>
);
