/* eslint-disable react/jsx-props-no-spreading */
import { FullPage } from 'components/Layout/FullPageLayout';
import { LoginView } from 'components/login/LoginView';
import { DecodedParamsRoute } from 'components/routing/DecodedParamsRoute';
import { TopLevelLoader } from 'components/TopLevelLoader';
import { WorkspaceNotFound } from 'components/WorkspaceNotFound';
import { useUserInfo } from 'hooks/useUserInfo';
import React from 'react';
import { putLocalStorage } from 'utils/localStorage';
import { type RouteProps, Redirect } from 'wouter';
import { useAuth0 } from '@auth0/auth0-react';

import { useWorkspace } from './WorkspaceProvider';

export const ProtectedRoute = (
  props: RouteProps & { path: `/w/:workspaceId/${string}` }
) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { workspace } = useWorkspace();
  const userInfo = useUserInfo();

  if (!isAuthenticated && !isLoading) {
    // If the user isn't logged in, store the route the user wants to go to
    // before redirected them to the login page.
    putLocalStorage('user_redirect_href', { path: window.location.href });
    // We don't want to replace the history state here because the user
    // will be unable to leave the dashboard
    return <Redirect href="/login" />;
  }

  // authenticates with auth0 but not fragment
  if (isAuthenticated && userInfo.type === 'user_not_found') {
    return <LoginView errorMessage="Email not found" />;
  }

  if (isAuthenticated && userInfo.data && !workspace) {
    return (
      <FullPage.Layout>
        <WorkspaceNotFound />
      </FullPage.Layout>
    );
  }

  if (isAuthenticated && userInfo.data) {
    return <DecodedParamsRoute {...props} />;
  }
  return <TopLevelLoader />;
};
