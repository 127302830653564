import clsx from 'clsx';
import React, { type ElementType } from 'react';

import type { TextProps } from './types';

// Polymorphic component forked from
// https://itnext.io/react-polymorphic-components-with-typescript-f7ce72ea7af2
export const Text = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp,
  LineClampProp,
  WhitespaceProp,
  T extends ElementType
>({
  truncate,
  group,
  as,
  fontFamily,
  fontWeight,
  fontStyle,
  fontSize,
  fontDecoration,
  lineHeight,
  color,
  textAlign,
  background,
  borderWidth,
  borderRadius,
  borderStyle,
  borderColor,
  cursor,
  position,
  placement,
  display,
  flexContainer,
  flexItem,
  w,
  h,
  maxW,
  maxH,
  minW,
  minH,
  padding,
  margin,
  transition,
  lineClamp,
  whitespace,
  extraClassNames,
  'data-testid': dataTestId,
  ...props
}: TextProps<
  T,
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp,
  LineClampProp,
  WhitespaceProp
>) => {
  const Component = as ?? 'span';

  return (
    <Component
      data-testid={dataTestId ?? 'text-engine'}
      // I think this is a good use case for prop spreading since we want to
      // leverage typechecking and be polymorphic
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={clsx(
        group && 'group',
        truncate && 'truncate',
        fontFamily ?? 'font-FragmentMono',
        fontWeight,
        fontStyle,
        fontSize ?? 'text-[inherit]',
        fontDecoration,
        lineHeight, // already set by default with fontSize, see tailwind config
        color ?? 'text-inherit',
        textAlign,
        background ?? 'bg-inherit',
        borderWidth ?? 'border-none',
        borderRadius,
        borderStyle,
        borderColor,
        // for the rest of these "Box" properties, opting for browser defaults
        cursor,
        position,
        placement,
        display,
        flexContainer,
        flexItem,
        w,
        h,
        maxW,
        maxH,
        minW,
        minH,
        padding,
        margin,
        // animation properties start here
        transition,
        lineClamp,
        whitespace,
        // use sparingly -- for one-off tailwind class uses that dont make sense as part of the text engine
        extraClassNames
      )}
    />
  );
};
