import type { DataExportRow } from 'components/settings/DataExportsList';
import { DeleteForm } from 'components/DeleteForm/DeleteForm';
import { ToastManagerContext } from 'components/ToastManager';
import { useDeleteDataExportMutation } from 'hooks/internalApi';
import { useContextSafely } from 'hooks/useContextSafely';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useCallback } from 'react';
import { getResultOrError } from 'utils/errors';
import { useWorkspaceId } from 'wrappers/WorkspaceProvider';

import { Toast } from '@fragment/ui/src/components/Toast';

type Props = {
  dataExport: DataExportRow;
  onDelete: () => void;
};
export const DeleteDataExportForm = ({ dataExport, onDelete }: Props) => {
  const { showToast } = useContextSafely(ToastManagerContext);
  const workspaceId = useWorkspaceId();
  const { context } = useInternalApiContext();
  const [{ fetching }, deleteDataExport] = useDeleteDataExportMutation();

  const handleDelete = useCallback(async () => {
    const { data, error } = await deleteDataExport(
      {
        workspaceId,
        dataExportId: dataExport.id,
      },
      context
    );
    const { isError } = getResultOrError(data?.deleteDataExport, error);
    if (isError) {
      showToast(
        <Toast type="error" message="Error Deleting Data Export, try again." />
      );
    } else {
      showToast(<Toast type="success" message="Export Deleted" />);
      onDelete();
    }
  }, [
    deleteDataExport,
    workspaceId,
    dataExport.id,
    context,
    showToast,
    onDelete,
  ]);

  return (
    <DeleteForm
      onConfirm={handleDelete}
      fetching={fetching}
      fetchingText="Deleting Export"
      confirmText={`Are you sure you want to delete '${dataExport.id}'? This will turn off the export permanently. Already exported data will remain in the bucket.`}
      confirmButtonText="Delete Export"
      buttonActionText="Delete Export"
    />
  );
};
