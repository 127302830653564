import clsx from 'clsx';
import React from 'react';

import type { TWMarginProp } from '../../types/tailwind';

type Props<MarginProp> = {
  marginClassName?: TWMarginProp<MarginProp>;
};

// TODO: make more extensible?
export const FillSpaceWithDots = <MarginProp,>({
  marginClassName,
}: Props<MarginProp>) => (
  /* Sorcery: this accomplishes the goal of "fill the remaining space with dots" by
   * creating a 9px-wide background image (bg-[size:9px_2px]) that consists of a 2px x 2px gray square
   * (from-main-600 from-[22%]) followed by a 7px x 2px transparent rectangle (bg-gradient-to-r, implicitly to transparent),
   * and repeating it (bg-repeat) for the full width (grow) at the appropriate position (bg-bottom mb-[4px])
   *
   * This avoids having to compute the number of dots to include in the text.
   *
   * We do this instead of just creating a bottom dotted border because this way gives us
   * control over the spacing & size between dots (see note in https://www.w3.org/TR/css-backgrounds-3/#the-border-style)
   */

  <div
    className={clsx([
      'bg-gradient-to-r from-main-500 from-[17%] bg-[size:13px_2px] bg-repeat-x bg-bottom grow',
      marginClassName ?? 'mb-[6px]',
    ])}
  />
);
