import type { DocumentNode } from 'graphql';
import type { PageInfo } from 'hooks/customerApi';
import type {
  AnyVariables,
  OperationResult,
  UseMutationResponse,
  UseQueryArgs,
} from 'urql';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

import type { GraphqlObject, ValueOrError } from './errors';
import { getResultOrError } from './errors';

/**
 *
 * @param useMutationFn The URQL mutation in hooks/internalApi.ts
 * @param responseKey The key in the GraphQL document that you want to check
 * against being an API Error
 * @returns  The same return signature as useMutation<Data, Variables> but sets
 * the response.error with the corresponding API error, if exists.
 * Currently, we have "network and server errors" that appear in the GQL errors dict,
 * but API errors appear as a union on the response, which is annoying to deal with.
 */
export function useCatchMutationError<
  Data extends GraphqlObject,
  Variables extends AnyVariables
>(
  useMutationFn: () => UseMutationResponse<Data, Variables>,
  responseKey: keyof Data
): ReturnType<typeof useMutationFn> {
  const [mutationState, mutationFn] = useMutationFn();
  if (mutationState.data !== undefined || mutationState.error !== undefined) {
    const state = getResultOrError(
      // TODO: Fix mismatched type
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mutationState.data?.[responseKey] as ValueOrError<
        // TODO: Fix mismatched type
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Data[typeof responseKey]
      >,
      mutationState.error
    );
    if (state.isError) {
      if (mutationState.error) {
        mutationState.error.message = state.errorMessage;
      } else {
        mutationState.error = {
          message: state.errorMessage,
          graphQLErrors: [],
          name: 'API Error',
        };
      }
    }
  }

  // Override the mutation function
  const newMutationFn = useCallback(
    async (
      ...args: Parameters<typeof mutationFn>
    ): Promise<OperationResult<Data, Variables>> => {
      const res = await mutationFn(...args);
      const mutationResult = getResultOrError(
        res.data?.[responseKey] as unknown as ValueOrError<Data>,
        res.error
      );
      if (mutationResult.isError) {
        if (res.error) {
          res.error.message = mutationResult.errorMessage;
        } else {
          res.error = {
            message: mutationResult.errorMessage,
            graphQLErrors: [],
            name: 'API Error',
          };
        }
      }
      return res;
    },
    [mutationFn, responseKey]
  );
  return [mutationState, newMutationFn];
}

export type PaginatedQueryVariables = {
  after?: string | null;
};

export type Connection<T> = {
  nodes?: T[] | null;
  pageInfo: Omit<PageInfo, '__typename'>;
};

export type UsePaginatedQueryArgs<
  T,
  Query,
  QueryVariables extends PaginatedQueryVariables
> = {
  getConnection: (q: Query) => Connection<T> | undefined | null;
  queryDocument: DocumentNode;
  queryOptions?: Partial<Omit<UseQueryArgs<QueryVariables>, 'query'>>;
  variables?: Partial<QueryVariables>;
  /** Specify to set poll interval */
  pollInterval?: number;
  /** Whether or not to paginate in reverse - pass this in when we specify `last: number` to a filter */
  reverse?: boolean;
  pageSize?: number;
};

export const PAGE_SIZE = 20;

export function usePaginatedQuery<
  T,
  Query,
  QueryVariables extends PaginatedQueryVariables
>({
  getConnection,
  queryDocument,
  queryOptions,
  variables: propVariables,
  pollInterval,
  reverse = false,
  pageSize = PAGE_SIZE,
}: UsePaginatedQueryArgs<T, Query, QueryVariables>) {
  const [cursor, setCursor] = useState<string>();
  const variables = useMemo(
    () => ({
      [reverse ? 'last' : 'first']: cursor ? undefined : pageSize,
      [reverse ? 'before' : 'after']: cursor,
      ...propVariables,
    }),
    [cursor, pageSize, propVariables, reverse]
  );
  const [response, reExecuteQuery] = useQuery<Query>({
    query: queryDocument,
    ...queryOptions,
    variables,
  });
  const connection = response.data ? getConnection(response.data) : null;
  const [oldData, setOldData] = useState<T[]>([]);

  const data = useMemo(
    () => [...oldData, ...(connection?.nodes || [])],
    [connection, oldData]
  );
  const paginateNext = useCallback(() => {
    if (
      !reverse &&
      connection &&
      connection.pageInfo.hasNextPage &&
      connection.pageInfo.endCursor
    ) {
      setCursor(connection.pageInfo.endCursor);
      setOldData(data);
    } else if (
      reverse &&
      connection &&
      connection.pageInfo.hasPreviousPage &&
      connection.pageInfo.startCursor
    ) {
      setCursor(connection.pageInfo.startCursor);
      setOldData(data);
    }
  }, [connection, data, reverse]);

  const hasMore =
    (reverse
      ? connection?.pageInfo.hasPreviousPage
      : connection?.pageInfo.hasNextPage) ?? false;

  useEffect(() => {
    if (response.fetching || pollInterval === undefined) return undefined;

    const timerId = setTimeout(() => {
      reExecuteQuery({ requestPolicy: 'network-only' });
    }, pollInterval);

    return () => clearTimeout(timerId);
  }, [response.fetching, reExecuteQuery, pollInterval]);

  return { response, reExecuteQuery, paginateNext, connection, data, hasMore };
}
