import React, { useCallback, useId, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { TertiaryButton } from '@fragment/ui/src/components/Button/TertiaryButton/TertiaryButton';
import { Icon } from '@fragment/ui/src/components/Icon';
import { TextInput } from '@fragment/ui/src/components/Input/TextInput';
import { Table } from '@fragment/ui/src/components/Table';
import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';

import type { ApiClient } from '../../../apiClient/NewApiClient/ApiClientForm';
import type { APIClientNode } from '../../../apiClient/types';
import { useGetWorkspaceApiClientQuery } from '../../../../hooks/internalApi';
import { useInternalApiContext } from '../../../../hooks/useInternalApiContext';
import { getResultOrError } from '../../../../utils/errors';
import {
  useWorkspace,
  useWorkspaceId,
} from '../../../../wrappers/WorkspaceProvider';
import { DeleteClientForm } from '../../../apiClient/ClientDetail/DeleteClientForm';
import { NewApiClient } from '../../../apiClient/NewApiClient';
import { TableLoadError } from '../../../TableLoadError/TableLoadError';

const schema = [
  {
    title: 'Name',
    key: 'clientName',
  },
  {
    title: 'ID',
    key: 'id',
    justify: 'right',
  },
] as const;

const emptyValue = {
  ID: 'N/A',
  Name: 'No API clients yet',
};

// Almost copy-pasta from https://github.com/fragment-dev/workspaces/blob/0154b039ab9ba0dac8df480f3036c08de9155ca5/dashboard/src/components/apiClient/ClientDetail/ClientDetail.tsx
const ClientDetail = ({
  apiClient,
  onConfirmedDelete,
}: {
  apiClient: APIClientNode;
  onConfirmedDelete: VoidFunction;
}) => {
  const namespace = useId();
  const apiURLFieldId = `${namespace}-api-url`;
  const clientFieldId = `${namespace}-client-id`;
  const OAuthDomainId = `${namespace}-OAuthDomain`;
  const OAuthScope = `${namespace}-OAuthScope`;
  const { workspace: activeWorkspaceFromContext } = useWorkspace();

  return (
    <div>
      <div className="space-y-f2">
        <div>
          <TextInput
            flexContainer="space-y-f0"
            id={clientFieldId}
            label="Client ID"
            padding="p-0"
            value={apiClient.id}
            size="s20"
            width="w-full"
            readOnly
          />
        </div>
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            id={apiURLFieldId}
            label="API URL"
            size="s20"
            width="w-full"
            padding="p-0"
            border={false}
            value={activeWorkspaceFromContext.pod.customerApiUrl}
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            id={OAuthDomainId}
            value={`https://${activeWorkspaceFromContext.pod.cognitoAuthDomain}/oauth2/token`}
            width="w-full"
            size="s20"
            padding="p-0"
            border={false}
            label="OAuth URL"
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            id={OAuthScope}
            value={activeWorkspaceFromContext.pod.customerApiOAuthScope}
            width="w-full"
            border={false}
            padding="p-0"
            size="s20"
            label="OAuth Scope"
          />
        )}
        <DeleteClientForm apiClient={apiClient} onDelete={onConfirmedDelete} />
      </div>
    </div>
  );
};

export const ApiClientsSettingsForm = () => {
  const workspaceId = useWorkspaceId();
  const { context } = useInternalApiContext();
  const variables = useMemo(() => ({ workspaceId }), [workspaceId]);
  const [{ data, error, fetching }, refetchApiClients] =
    useGetWorkspaceApiClientQuery({
      variables,
      context,
      pause: !context.token,
    });
  const { isError: isWorkspaceError, result: workspace } = getResultOrError(
    data?.workspace,
    error
  );
  const { isError: isApiClientsError, result: apiClients } = getResultOrError(
    workspace?.apiClients,
    error
  );
  const [selectedClient, setSelectedClient] = useState<
    APIClientNode | undefined
  >(undefined);
  const [createdApiClient, setCreatedApiClient] = useState<
    ApiClient | 'PENDING' | undefined
  >(undefined);

  const refresh = useCallback(
    () => refetchApiClients({ requestPolicy: 'network-only' }),
    [refetchApiClients]
  );

  const onCreate = useCallback(
    (client: ApiClient) => {
      setCreatedApiClient(client);
      refresh();
    },
    [setCreatedApiClient, refresh]
  );

  const onRowClick = useCallback(
    (client: APIClientNode) => setSelectedClient(client),
    []
  );
  const isError = isWorkspaceError || isApiClientsError || !data;

  const onCancel = useCallback(() => {
    setSelectedClient(undefined);
    setCreatedApiClient(undefined);
  }, [setSelectedClient, setCreatedApiClient]);

  const onConfirmedDelete = useCallback(() => {
    setSelectedClient(undefined);
    refresh();
  }, [setSelectedClient, refresh]);
  return (
    <div className="flex flex-col space-y-f4">
      <div className="flex justify-between">
        <span>
          {match([selectedClient, createdApiClient])
            .with([undefined, undefined], () => 'API Clients')
            .otherwise(() => (
              <TertiaryButton onClick={onCancel}>
                <div className="flex space-x-[1ch] items-center">
                  <Icon type="left" size="text-s20" />
                  <span>Back</span>
                </div>
              </TertiaryButton>
            ))}
        </span>
        {selectedClient || createdApiClient ? null : (
          <PrimaryButtonV2
            onClick={() => setCreatedApiClient('PENDING')}
            secondaryIcon="+"
          >
            New Client
          </PrimaryButtonV2>
        )}
      </div>
      {match([selectedClient, isError, createdApiClient])
        // If there's any error
        .with([P._, true, P._], () => (
          <TableLoadError
            entityColumnName="Name"
            rightColumnName="ID"
            {...{ error, retry: refresh, fetching }}
          />
        ))
        // If there's no error, but we haven't selected or created an API client
        .with([undefined, false, undefined], () => (
          <Table
            textSize="s20"
            data={apiClients?.nodes ?? []}
            onRowClick={onRowClick}
            schema={schema}
            emptyValue={emptyValue}
          />
        ))
        // We've selected an API client
        .with([P.not(undefined), false, undefined], () => (
          <ClientDetail
            apiClient={selectedClient!}
            onConfirmedDelete={onConfirmedDelete}
          />
        ))
        // We're creating an API client
        .with([P._, false, P.select()], (client) => (
          <NewApiClient
            onCreate={onCreate}
            onCancel={onCancel}
            apiClient={client === 'PENDING' ? undefined : client}
          />
        ))
        .exhaustive()}
    </div>
  );
};
