import React from 'react';

import { useLoadingText } from '../../hooks/LoadingText';
import { useLoopingTicker } from '../../hooks/useTicker';

type Props = {
  text: string;
};

export const LoadingText = ({ text }: Props) => {
  const displayValue = useLoadingText(text);
  return (
    <span
      style={{
        fontVariantLigatures: 'no-contextual',
      }}
    >
      <span>{displayValue}</span>
    </span>
  );
};

export const LoadingIndicator = () => {
  const frames = ['/', '-', '\\'];
  const tick = useLoopingTicker(300, frames.length);
  return <span>{frames[tick % frames.length]}</span>;
};
