import React, { type ReactNode, Children, isValidElement } from 'react';

type FooterProps = {
  children: ReactNode;
};

const FullPageLayoutFooter = ({ children }: FooterProps) => (
  <div className="absolute bottom-f4 left-f4">{children}</div>
);

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  let footer: ReactNode | undefined;
  const childrenArray = Children.toArray(children);
  const nonFooterChildren = childrenArray.filter((child) => {
    if (isValidElement(child) && child.type === FullPageLayoutFooter) {
      footer = child;
      return false;
    }
    return true;
  });
  return (
    <div className="text-s20 h-screen py-f2 px-f2 relative flex flex-col">
      {nonFooterChildren}
      {footer}
    </div>
  );
};

export const FullPage = {
  Layout,
  Footer: FullPageLayoutFooter,
};
