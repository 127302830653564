import { useToast } from 'components/ToastManager';
import { useCreateDataExportMutation } from 'hooks/internalApi';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { useCallback } from 'react';
import { getResultOrError } from 'utils/errors';
import { useLocation } from 'wouter';
import { useWorkspaceId } from 'wrappers/WorkspaceProvider';

import { MultiPartForm } from '@fragment/ui/src/components/MultiPartForm/MultiPartForm';
import { Toast } from '@fragment/ui/src/components/Toast';

import { NewDataExportFormAWSPage } from './NewDataExportFormAWSPage';
import { NewDataExportFormCreateExportPage } from './NewDataExportFormCreateExportPage';

type Props = {
  onSuccess: VoidFunction;
  onCancel: VoidFunction;
};

export const NewDataExportForm = ({ onSuccess, onCancel }: Props) => {
  const { showToast } = useToast();
  const [, setLocation] = useLocation();
  const [, createDataExport] = useCreateDataExportMutation();
  const { context } = useInternalApiContext();
  const workspaceId = useWorkspaceId();

  const handleSubmit = useCallback(
    async (fieldValues: Record<string, string>) => {
      const { data, error } = await createDataExport(
        {
          workspaceId,
          dataExport: {
            id: fieldValues.exportName,
            exportBucketName: fieldValues.bucketName,
            exportBucketRegion: fieldValues.bucketRegion,
            exportBucketKey: fieldValues.filePrefix,
          },
        },
        context
      );
      const res = getResultOrError(data?.createDataExport, error);
      if (res.isError) {
        showToast(<Toast message="Error Creating Data Export" type="error" />);
      } else {
        showToast(
          <Toast
            message="Export Created. Data should arrive within 15 minutes."
            type="success"
          />
        );
        onSuccess();
      }
    },
    [createDataExport, workspaceId, context, showToast, onSuccess]
  );

  return (
    <MultiPartForm
      preventEventDefault
      pages={[NewDataExportFormAWSPage, NewDataExportFormCreateExportPage]}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};
