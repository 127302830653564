import type { FC } from 'react';
import React from 'react';

import type { ApiClient } from './ApiClientForm';
import { ApiClientCreatedView } from './ApiClientCreatedView';
import { ApiClientForm } from './ApiClientForm';

export type NewApiClientProps = {
  onCreate: (value: ApiClient) => void;
  onCancel: VoidFunction;
  apiClient: ApiClient | undefined;
};
export const NewApiClient: FC<NewApiClientProps> = ({
  onCreate,
  onCancel,
  apiClient: client,
}) =>
  client === undefined ? (
    <ApiClientForm
      key="form"
      onCancel={onCancel}
      onCreate={(response) => {
        onCreate(response);
      }}
    />
  ) : (
    <ApiClientCreatedView
      key="view"
      onDone={onCancel}
      clientId={client.client.id}
      clientName={client.client.clientName}
      clientSecret={client.secret}
    />
  );
