/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import type {
  MarkerProps,
  OrderedMarkerProps,
  UnorderedMarkerProps,
} from './types';
import { Text } from '../../Text';

/*
 * <Marker />
 * This component is responsible for rendering the marker that goes with a list item.
 * I am creating a separate element (as opposed to having it managed by the list so
 * that it can be inside an interactive component ie. button.)
 */

const PunctuationMap = new Map([
  ['period', '.'],
  ['parenthesis', ')'],
] as const);

const UnorderedMarkerMap = new Map([
  ['bullet', '\u2022'],
  ['dash', '-'],
] as const);

export const convertOrderToAlphaMarker = (
  textCase: 'upper' | 'lower',
  order: number
): string => {
  let marker = '';
  let cursor = order;
  const startingChar = textCase === 'upper' ? 'A' : 'a';
  let memo = Math.floor(cursor / 26);
  while (memo >= 1) {
    marker += String.fromCharCode(startingChar.charCodeAt(0) + memo - 1);
    cursor %= 26;
    memo = Math.floor(cursor / 26);
  }
  marker += String.fromCharCode(startingChar.charCodeAt(0) + cursor);
  return marker;
};

const OrderedMarker = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>({
  punctuation,
  type,
  ordinal,
  ...textProps
}: OrderedMarkerProps<
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>) => {
  let marker: string;
  switch (type) {
    case 'numeric':
      marker = `${ordinal + 1}`;
      break;
    case 'lower-alphabetic':
      marker = convertOrderToAlphaMarker('lower', ordinal);
      break;
    case 'upper-alphabetic':
      marker = convertOrderToAlphaMarker('upper', ordinal);
      break;
    default:
      throw new Error(`reached unreachable code`);
  }
  return (
    <Text {...textProps}>{`${marker}${
      punctuation ? PunctuationMap.get(punctuation) : ''
    }`}</Text>
  );
};

const UnorderedMarker = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>({
  type,
  ...textProps
}: UnorderedMarkerProps<
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>) => <Text {...textProps}>{UnorderedMarkerMap.get(type)}</Text>;

export const Marker = <
  FontFamilyProp,
  FontWeightProp,
  FontStyleProp,
  FontSizeProp,
  FontDecorationProp,
  LineHeightProp,
  ColorProp,
  TextAlignProp,
  BackgroundProp,
  BorderWidthProp,
  BorderRadiusProp,
  BorderStyleProp,
  BorderColorProp,
  CursorProp,
  PositionProp,
  PlacementProp,
  DisplayProp,
  FlexContainerProp,
  FlexItemProp,
  WProp,
  HProp,
  MaxWProp,
  MaxHProp,
  MinWProp,
  MinHProp,
  PaddingProp,
  MarginProp,
  TransitionProp
>(
  props: MarkerProps<
    FontFamilyProp,
    FontWeightProp,
    FontStyleProp,
    FontSizeProp,
    FontDecorationProp,
    LineHeightProp,
    ColorProp,
    TextAlignProp,
    BackgroundProp,
    BorderWidthProp,
    BorderRadiusProp,
    BorderStyleProp,
    BorderColorProp,
    CursorProp,
    PositionProp,
    PlacementProp,
    DisplayProp,
    FlexContainerProp,
    FlexItemProp,
    WProp,
    HProp,
    MaxWProp,
    MaxHProp,
    MinWProp,
    MinHProp,
    PaddingProp,
    MarginProp,
    TransitionProp
  >
  // eslint is unhappy because it thinks there's a case where there's no
  // return. TS understands there's always a return so trusting that.
  // eslint-disable-next-line consistent-return
): JSX.Element => {
  const { type } = props;
  switch (type) {
    case 'bullet':
    case 'dash': {
      return <UnorderedMarker {...props} />;
    }
    case 'lower-alphabetic':
    case 'numeric':
    case 'upper-alphabetic': {
      return <OrderedMarker {...props} />;
    }
    default:
      throw new Error(`reached unreachable code`);
  }
};
