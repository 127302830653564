import { DeleteForm } from 'components/DeleteForm/DeleteForm';
import { useToast } from 'components/ToastManager';
import { useDeleteApiClientMutation } from 'hooks/internalApi';
import { useInternalApiContext } from 'hooks/useInternalApiContext';
import React, { type FC, useCallback } from 'react';
import { getResultOrError } from 'utils/errors';
import { useWorkspaceId } from 'wrappers/WorkspaceProvider';

import { Toast } from '@fragment/ui/src/components/Toast';

import type { APIClientNode } from '../types';

export type DeleteClientFormProps = {
  apiClient: APIClientNode;
  onDelete: () => void;
};
export const DeleteClientForm: FC<DeleteClientFormProps> = ({
  apiClient,
  onDelete,
}) => {
  const workspaceId = useWorkspaceId();
  const { context } = useInternalApiContext();
  const [{ fetching }, deleteClient] = useDeleteApiClientMutation();
  const { showToast } = useToast();

  const handleDelete = useCallback(async () => {
    const { data, error } = await deleteClient(
      {
        workspaceId,
        clientId: apiClient.id,
      },
      context
    );
    const { isError } = getResultOrError(data?.deleteApiClient, error);
    if (isError) {
      showToast(<Toast type="error" message="Error Deleting API Key" />);
    } else {
      showToast(<Toast type="success" message="Client Deleted" />);
      onDelete();
    }
  }, [deleteClient, workspaceId, apiClient.id, context, showToast, onDelete]);

  return (
    <DeleteForm
      onConfirm={handleDelete}
      fetching={fetching}
      fetchingText="Deleting Client"
      confirmText={`Are you sure you want to delete '${apiClient.clientName}'? This action cannot be undone.`}
      buttonActionText="Delete"
      confirmButtonText="Delete Client"
    />
  );
};
