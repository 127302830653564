import clsx from 'clsx';
import React from 'react';

import { Button } from '../BaseButton/Button';

type SecondaryButtonV2Props = Omit<
  JSX.IntrinsicElements['button'],
  'background' | 'fontDecoration' | 'extraClassNames' | 'color'
>;

export const SecondaryButtonV2 = ({
  onClick,
  disabled = false,
  children,
  className,
  type = 'button',
  ...props
}: SecondaryButtonV2Props) => (
  <Button
    type={type}
    onClick={onClick}
    disabled={disabled}
    extraClassNames={clsx(
      'hover:enabled:cursor-pointer hover:disabled:cursor-not-allowed',
      className
    )}
    background="disabled:bg-main-200 bg-main-200 hover:enabled:bg-negative"
    fontDecoration="no-underline"
    color="hover:disabled:text-main-500 disabled:text-main-500 enabled:text-main hover:enabled:text-main"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Button>
);
