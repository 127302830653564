import clsx from 'clsx';
import React, { type FC, useId } from 'react';
import { useWorkspace } from 'wrappers/WorkspaceProvider';
import { H } from '@highlight-run/next/client';

import { PrimaryButtonV2 } from '@fragment/ui/src/components/Button/PrimaryButtonV2/PrimaryButtonV2';
import { TextInputCopy } from '@fragment/ui/src/components/TextInputCopy/TextInputCopy';

type ApiClientCreatedProps = {
  clientId: string;
  clientSecret: string;
  clientName: string;
  onDone: VoidFunction;
};

export const ApiClientCreatedView: FC<ApiClientCreatedProps> = ({
  clientId,
  clientSecret,
  clientName,
  onDone,
}) => {
  const namespace = useId();
  const OAuthDomainId = `${namespace}-OAuthDomain`;
  const OAuthScope = `${namespace}-OAuthScope`;
  const clientFieldId = `${namespace}-client-id`;
  const clientSecretFieldId = `${namespace}-client-secret`;
  const apiURLFieldId = `${namespace}-api-url`;
  const { workspace: activeWorkspaceFromContext } = useWorkspace();
  H.track('API Client Created');
  return (
    <div className={clsx('space-y-f2', 'max-w-full ')}>
      <div className="space-y-f2">
        <TextInputCopy
          size="s20"
          id={clientFieldId}
          padding="py-f0 px-[0.5ch]"
          value={clientId}
          width="w-full"
          label="Client ID"
        />
        <div>
          <TextInputCopy
            size="s20"
            id={clientSecretFieldId}
            value={clientSecret}
            width="w-full"
            label="Client Secret"
            padding="py-f0 px-[0.5ch]"
          />
          <div className="italic">This key will not be shown again.</div>
        </div>
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="s20"
            id={apiURLFieldId}
            width="w-full"
            label="API URL"
            value={activeWorkspaceFromContext.pod.customerApiUrl}
            padding="py-f0 px-[0.5ch]"
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="s20"
            id={OAuthDomainId}
            value={`https://${activeWorkspaceFromContext.pod.cognitoAuthDomain}/oauth2/token`}
            padding="py-f0 px-[0.5ch]"
            width="w-full"
            label="OAuth URL"
          />
        )}
        {activeWorkspaceFromContext?.pod && (
          <TextInputCopy
            size="s20"
            id={OAuthScope}
            padding="py-f0 px-[0.5ch]"
            value={activeWorkspaceFromContext.pod.customerApiOAuthScope}
            width="w-full"
            label="OAuth Scope"
          />
        )}
      </div>
      <PrimaryButtonV2 onClick={onDone}>Done</PrimaryButtonV2>
    </div>
  );
};
