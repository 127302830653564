import type { CombinedError } from 'urql';
import { Summary } from 'components/Summary';
import React from 'react';

import { TextButton } from '@fragment/ui/src/components/Button/TextButton/TextButton';
import { Icon } from '@fragment/ui/src/components/Icon';
import { InlineError } from '@fragment/ui/src/components/InlineError/InlineError';
import { LoadingText } from '@fragment/ui/src/components/LoadingText';
import { useLoadingText } from '@fragment/ui/src/hooks/LoadingText';

export type TableLoadErrorProps = {
  entityColumnName: string;
  rightColumnName?: string;
  error?: CombinedError;
  notFoundErrorCode?: string;
  entityId?: string;
  retry: JSX.IntrinsicElements['button']['onClick'];
  fetching: boolean;
};

export const TableLoadError = ({
  entityColumnName,
  rightColumnName = '',
  error,
  notFoundErrorCode,
  entityId,
  retry,
  fetching,
}: TableLoadErrorProps): JSX.Element => {
  const loadingText = useLoadingText('Loading');
  if (
    error?.graphQLErrors.some(
      (err) =>
        err.extensions.code === notFoundErrorCode || err.message === 'Not found'
    )
  ) {
    return (
      <div className="flex flex-col">
        <span className="text-main-500">{entityColumnName}</span>
        {fetching ? (
          <span className="mt-f2">{loadingText}</span>
        ) : (
          <span>{`"${entityId}" Not Found`}</span>
        )}
      </div>
    );
  }
  return (
    <Summary.Table
      values={[
        {
          header: entityColumnName,
          value: (
            <InlineError margin="m-f0">
              {fetching ? <LoadingText text="Loading" /> : 'Loading Error'}
            </InlineError>
          ),
        },
        {
          header: rightColumnName,
          value: (
            <div>
              <TextButton inverted onClick={retry}>
                Retry <Icon type="right" />
              </TextButton>
            </div>
          ),
        },
      ]}
    />
  );
};
