import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import type {
  TWBorderColorProp,
  TWFlexContainerProp,
  TWHeightProp,
  TWPaddingProp,
  TWWidthProp,
} from '@fragment/ui/src/types/tailwind';

import { Button } from '../Button/BaseButton/Button';
import { Text } from '../Text';

type ToggleProps<
  WidthProp,
  HeightProp,
  PaddingProp,
  FlexContainerProp,
  BorderColorProp
> = {
  label?: string;
  leftLabel: React.ReactNode;
  rightLabel: React.ReactNode;
  onLeftClick: () => void;
  onRightClick: () => void;
  readOnly?: boolean;
  inline?: boolean;
  width?: TWWidthProp<WidthProp>;
  buttonHeight?: TWHeightProp<HeightProp>;
  buttonPadding?: TWPaddingProp<PaddingProp>;
  flexContainer?: TWFlexContainerProp<FlexContainerProp>;
  borderColor?: TWBorderColorProp<BorderColorProp>;
  'data-testid'?: string;
} & (
  | {
      controlled: true;
      activeButton: 'left' | 'right';
      initialActiveButton?: undefined;
    }
  | {
      controlled?: false;
      activeButton?: undefined;
      initialActiveButton: 'left' | 'right';
    }
);

export const Toggle = <
  WidthProp,
  HeightProp,
  PaddingProp,
  FlexContainerProp,
  BorderColorProp
>({
  label,
  onLeftClick,
  onRightClick,
  leftLabel,
  rightLabel,
  initialActiveButton,
  controlled,
  readOnly,
  activeButton: activeButtonFromProps,
  'data-testid': dataTestId,
  width,
  inline = false, // if it's inline, remove top and bottom borders
  buttonHeight = 'h-f4' as TWHeightProp<HeightProp>,
  buttonPadding = 'px-f2' as TWPaddingProp<PaddingProp>,
  borderColor = 'border-canvas' as TWBorderColorProp<BorderColorProp>,
  flexContainer = 'space-y-f1' as TWFlexContainerProp<FlexContainerProp>,
}: ToggleProps<
  WidthProp,
  HeightProp,
  PaddingProp,
  FlexContainerProp,
  BorderColorProp
>) => {
  const [activeButton, setActiveButton] = useState<'left' | 'right'>(
    initialActiveButton ?? activeButtonFromProps
  );
  const [hoveredButton, setHoveredButton] = useState<'left' | 'right' | null>(
    null
  );
  const handleLeftButtonClicked = useCallback(() => {
    if (!readOnly) {
      if (!controlled) {
        setActiveButton('left');
      }
      onLeftClick();
    }
  }, [onLeftClick, setActiveButton, controlled, readOnly]);
  const handleLeftButtonHovered = useCallback(() => {
    if (!readOnly) {
      setHoveredButton('left');
    }
  }, [setHoveredButton, readOnly]);
  const handleRightButtonClicked = useCallback(() => {
    if (!readOnly) {
      if (!controlled) {
        setActiveButton('right');
      }
      onRightClick();
    }
  }, [onRightClick, setActiveButton, controlled, readOnly]);
  const handleRightButtonHovered = useCallback(() => {
    if (!readOnly) {
      setHoveredButton('right');
    }
  }, [setHoveredButton, readOnly]);
  const handleHoverExit = useCallback(() => {
    if (!readOnly) {
      setHoveredButton(null);
    }
  }, [setHoveredButton, readOnly]);

  const activeLabel = controlled ? activeButtonFromProps : activeButton;

  return (
    <div
      className={clsx('flex flex-col', width, flexContainer)}
      data-testid={dataTestId}
    >
      {label && (
        <Text as="label" color="text-main-500">
          {label}
        </Text>
      )}
      <div className="flex flex-nowrap">
        <Button
          type="button"
          data-selected={activeLabel === 'left'}
          background={activeLabel === 'left' ? 'bg-negative' : 'bg-main-200'}
          color={
            hoveredButton === 'left' || activeLabel === 'left'
              ? 'text-main'
              : 'text-main-500 enabled:hover:text-main'
          }
          borderWidth="border-[1px] border-r-[0px]"
          borderStyle="border-solid"
          extraClassNames={inline ? 'border-t-[0px] border-b-[0px]' : ''}
          borderColor={borderColor}
          padding={buttonPadding}
          h={buttonHeight}
          disabled={readOnly}
          cursor={readOnly ? 'hover:cursor-not-allowed' : undefined}
          onClick={handleLeftButtonClicked}
          onMouseOver={handleLeftButtonHovered}
          onFocus={handleLeftButtonHovered}
          onMouseOut={handleHoverExit}
          onBlur={handleHoverExit}
        >
          {leftLabel}
        </Button>
        <Button
          type="button"
          data-selected={activeLabel === 'right'}
          background={activeLabel === 'right' ? 'bg-negative' : 'bg-main-200'}
          color={
            hoveredButton === 'right' || activeLabel === 'right'
              ? 'text-main'
              : 'text-main-500 enabled:hover:text-main'
          }
          borderWidth="border-[1px]"
          borderStyle="border-solid"
          borderColor={borderColor}
          extraClassNames={inline ? 'border-t-[0px] border-b-[0px]' : ''}
          padding={buttonPadding}
          disabled={readOnly}
          h={buttonHeight}
          cursor={readOnly ? 'hover:cursor-not-allowed' : undefined}
          onClick={handleRightButtonClicked}
          onMouseOver={handleRightButtonHovered}
          onFocus={handleRightButtonHovered}
          onMouseOut={handleHoverExit}
          onBlur={handleHoverExit}
        >
          {rightLabel}
        </Button>
      </div>
    </div>
  );
};
