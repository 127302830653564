import clsx from 'clsx';
import React, { type PropsWithChildren } from 'react';

import { FillSpaceWithDots } from '@fragment/ui/src/components/FillSpaceWithDots';

type RowProps = PropsWithChildren<{
  label: string;
}>;

const Row = ({ label, children }: RowProps) => (
  <div className="flex flex-nowrap">
    <div className="block shrink-0 basis-[11ch] text-main-500">{label}</div>
    {typeof children === 'string' ? (
      <span className="shrink min-w-f0 truncate">{children}</span>
    ) : (
      <div className="flex shrink min-w-f0">{children}</div>
    )}
  </div>
);

type Column = {
  header:
    | string
    | {
        key: string;
        component: React.ReactElement;
      };
  value: React.ReactElement;
  truncate?: boolean;
};

type TableProps = {
  values: Column[];
};

export const Table = ({ values }: TableProps): JSX.Element => (
  <div className={clsx('flex flex-col items-center')}>
    <div className="flex flex-row justify-between items-center w-full">
      {values.map(({ header }, i) => {
        const firstOrNotLast = i === 0 || i < values.length - 1;
        if (typeof header === 'string') {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${header}_${i}`}
              className={clsx(
                'text-main-500',
                'flex flex-row',
                firstOrNotLast ? 'grow' : '-px-[10px]'
              )}
            >
              <div className="text-main-500 truncate" key={header}>
                {header}
              </div>
              {firstOrNotLast && <FillSpaceWithDots />}
            </div>
          );
        }

        return <div key={header.key}>{header.component}</div>;
      })}
    </div>
    <div className="flex flex-row justify-between  w-full space-x-f2">
      {values.map(({ header, value, truncate }, i) => (
        // TODO: update text engine to be able to handle this
        <div
          key={typeof header === 'string' ? `${header}_${i}` : header.key}
          className={clsx(truncate && 'truncate flex-grow', 'group-one')}
        >
          {value}
        </div>
      ))}
    </div>
  </div>
);

export const Summary = {
  Row,
  Table,
};
