import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Liability: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  backgroundColorHexCode = colorToHexCode['liability-red'],
  primaryColorHexCode = colorToHexCode.white,
  includeSecondaryMark = false,
  'data-testid': testId,
}) => (
  <svg
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <circle cx="6" cy="6" r="6" fill={backgroundColorHexCode} />
    <path
      d="M4.00366 9.29678H8.95966V8.38478H5.05966V2.30078H4.00366V9.29678Z"
      fill={primaryColorHexCode}
    />
    {includeSecondaryMark && (
      <path
        d="M12 1.5C12 2.32843 11.3284 3 10.5 3C9.67157 3 9 2.32843 9 1.5C9 0.671573 9.67157 0 10.5 0C11.3284 0 12 0.671573 12 1.5Z"
        fill="#0D0D0D"
      />
    )}
  </svg>
);
