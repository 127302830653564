import React, {
  type PropsWithChildren,
  createContext,
  useMemo,
  useState,
} from 'react';

export type HelpModeContextValue = {
  helpMode: boolean;
  setHelpMode: (mode: boolean) => void;
};

const defaultContext: HelpModeContextValue = {
  helpMode: false,
  setHelpMode: () => {},
};

export const HelpModeContext =
  createContext<HelpModeContextValue>(defaultContext);

export type HelpModeProviderProps = PropsWithChildren<{
  helpMode?: HelpModeContextValue['helpMode'];
}>;

export const HelpModeProvider = ({
  helpMode: defaultHelpMode = false,
  children,
}: HelpModeProviderProps) => {
  const [helpMode, setHelpMode] = useState<boolean>(defaultHelpMode);

  const contextValue = useMemo(
    () => ({
      helpMode,
      setHelpMode,
    }),
    [helpMode, setHelpMode]
  );

  return (
    <HelpModeContext.Provider value={contextValue}>
      {children}
    </HelpModeContext.Provider>
  );
};
