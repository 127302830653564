import React from 'react';

import { ColorModeSelector } from '@fragment/ui/src/components/ColorMode/ColorModeSelector';

export const Preferences = () => (
  <div>
    <div className="pb-f4">
      <span className="text-main">Preferences</span>
    </div>
    <div>
      <span className="text-main-500">Theme</span>
      <ColorModeSelector />
    </div>
  </div>
);
