import React from 'react';

import { type BaseSvgProps, colorToHexCode } from '../../utils';

export const ExitLeftA = ({
  primaryColorHexCode = colorToHexCode.black,
}: BaseSvgProps) => (
  <svg
    width="15"
    height="15"
    className="shrink-0"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1176 0.0314941H1.89386V14.0315H2.60443V0.744393H11.4057V9.90591H12.1176L12.1176 0.0314941Z"
      fill={primaryColorHexCode}
    />
    <rect
      width="0.723143"
      height="2.0144"
      transform="matrix(-1 0 0 1 12.1191 12.0171)"
      fill={primaryColorHexCode}
    />
    <path
      d="M5.72224 1.66187C6.35675 1.66187 6.84435 2.14946 6.84435 2.78734C6.84435 3.42643 6.35666 3.91402 5.72224 3.91402C5.08769 3.91402 4.60134 3.42643 4.60134 2.78734C4.60134 2.14946 5.08773 1.66182 5.72224 1.66187ZM5.85789 4.19298C6.96622 4.19298 9.17971 4.19658 9.17971 4.19658C9.6534 4.21127 9.73665 4.30478 9.91896 4.57588L10.9555 6.30972C11.2713 6.98405 10.3292 7.44772 9.98054 6.81196L9.13522 5.33111C9.11923 5.30376 9.0954 5.29702 9.06472 5.29808L7.89688 5.31171C8.21603 5.99283 8.53508 6.67308 8.85391 7.35661C8.95171 7.61173 8.98137 7.84536 9.00775 8.18248C9.04989 8.86148 9.08759 9.54492 9.12723 10.224C9.13979 10.314 9.16936 10.3744 9.27761 10.3744C10.131 10.371 11.7633 10.3526 11.7633 10.3526C12.6109 10.3856 13.1315 11.0794 13.1315 11.6048L8.56109 11.5943C8.15666 11.5817 7.848 11.4657 7.81159 11.0088L7.67253 8.36936C6.93545 9.85261 6.19616 11.3347 5.46029 12.8178C5.27683 13.1402 5.05927 13.5002 4.53754 13.4888L3.53953 13.4921L6.25997 7.99902C6.31467 7.89535 6.24167 7.76215 6.21085 7.70293C5.93744 7.12316 5.67891 6.61261 5.40434 6.01224L4.41455 7.1823C4.25133 7.34543 4.20199 7.38576 3.97385 7.39015C3.9684 7.39052 3.96304 7.39071 3.95745 7.39085C3.94904 7.39103 3.94475 7.39205 3.93597 7.39205C3.92271 7.39205 3.91014 7.39145 3.89688 7.39135H2.5772C2.5772 7.39135 2.5772 7.11124 2.5772 6.81196C2.5772 6.51268 2.5772 6.31111 2.5772 6.31111H2.88566C3.09952 6.29863 3.34188 6.2936 3.61816 6.29826C3.76739 6.29951 3.80268 6.26749 3.86533 6.19233C4.31652 5.66038 4.82227 5.05193 5.28473 4.48126C5.45669 4.2979 5.4978 4.18498 5.85789 4.19298Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
