import type { ReactNode } from 'react';
import { PodApiAuthenticationFailed } from 'components/AuthenticationFailed';
import { FullPage } from 'components/Layout/FullPageLayout';
import { TopLevelLoader } from 'components/TopLevelLoader';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useWorkspace } from 'wrappers/WorkspaceProvider';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  children: ReactNode;
};

type PodApiAccessTokenContext = {
  customerApiToken: string | null;
  internalApiToken: string | null;
};

const defaultContext = {
  customerApiToken: null,
  internalApiToken: null,
};

export const PodApiAccessToken =
  createContext<PodApiAccessTokenContext>(defaultContext);

export const PodApiAccessTokenProvider = ({ children }: Props) => {
  const { workspace } = useWorkspace();
  const activeWorkspaceId = workspace?.id;

  const [internalApiToken, setInternalApiToken] = useState<string | null>(null);
  const [customerApiToken, setCustomerApiToken] = useState<string | null>(null);

  const [didTokenFetchFail, setDidTokenFetchFail] = useState<boolean>(false);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isTokenFetching, setIsFetching] = useState(false);

  const workspaceId = workspace ? workspace.id : '';
  useMemo(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated && user?.sub && workspace?.pod) {
        try {
          setIsFetching(true);
          const [internalToken, customerToken] = await Promise.all([
            getAccessTokenSilently({
              authorizationParams: {
                audience: workspace.pod.internalApiAudience,
              },
            }),
            getAccessTokenSilently({
              authorizationParams: {
                audience: workspace.pod.customerApiAudience,
              },
            }),
          ]);
          setInternalApiToken(internalToken);
          setCustomerApiToken(customerToken);
        } catch (error) {
          setDidTokenFetchFail(true);
        } finally {
          setIsFetching(false);
        }
      }
    };

    fetchAccessToken();
    // using workspace id string as a
    // proxy for when the object changes
    // TODO create a better way to do this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated, user?.sub, workspaceId]);

  const context: PodApiAccessTokenContext = useMemo(
    () => ({
      customerApiToken,
      internalApiToken,
    }),
    [customerApiToken, internalApiToken]
  );

  // if Customer API token or internal API token fetches fail.
  // Any subsequent page can assume that if the token fetch failed, that it is handled here
  if (didTokenFetchFail) {
    return (
      <FullPage.Layout>
        <PodApiAuthenticationFailed />
      </FullPage.Layout>
    );
  }

  if (
    isTokenFetching ||
    (!context.customerApiToken &&
      !context.internalApiToken &&
      activeWorkspaceId)
  ) {
    return <TopLevelLoader />;
  }
  return (
    <PodApiAccessToken.Provider value={context}>
      {children}
    </PodApiAccessToken.Provider>
  );
};

export const usePodApiAccessTokenContext = () => useContext(PodApiAccessToken);
