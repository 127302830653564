import type { ReactNode } from 'react';
import clsx from 'clsx';
import React from 'react';

import { PrimaryButtonV2 } from '../PrimaryButtonV2/PrimaryButtonV2';
import { SecondaryButtonV2 } from '../SecondaryButtonV2/SecondaryButtonV2';
import { TertiaryButton } from '../TertiaryButton/TertiaryButton';

export type FormButtonsProps = {
  type: 'tertiary' | 'secondary';
  onCancel: () => void;
  onSubmit?: () => void;
  cancelButtonLabel: ReactNode;
  submitButtonLabel: ReactNode;
  cancelButtonTestId?: string;
  submitButtonTestId?: string;
  disabled?: boolean;
};

export const FormButtons = ({
  type,
  onCancel,
  onSubmit,
  cancelButtonTestId,
  cancelButtonLabel,
  submitButtonLabel,
  submitButtonTestId,
  disabled = false,
}: FormButtonsProps) => (
  <div
    className={clsx('flex flex-row space-x-[1ch]')}
    data-testid="form-buttons"
  >
    {type === 'tertiary' ? (
      <TertiaryButton data-testid={cancelButtonTestId} onClick={onCancel}>
        {cancelButtonLabel}
      </TertiaryButton>
    ) : (
      <SecondaryButtonV2 data-testid={cancelButtonTestId} onClick={onCancel}>
        {cancelButtonLabel}
      </SecondaryButtonV2>
    )}
    <PrimaryButtonV2
      data-testid={submitButtonTestId}
      disabled={disabled}
      type={onSubmit ? 'button' : 'submit'}
      onClick={onSubmit}
    >
      {submitButtonLabel}
    </PrimaryButtonV2>
  </div>
);
