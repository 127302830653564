import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, sizeToSvgWidth } from '../../utils';

export const Bank: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  'data-testid': testId,
}) => (
  <svg
    width={16}
    height={16}
    className="shrink-0"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
  >
    <g clipPath="url(#clip0_7300_55005)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41329 3.70441C6.34988 3.67145 6.27356 3.67145 6.21016 3.70441L3.42516 5.15233C3.35577 5.1884 3.3125 5.25835 3.3125 5.33445V5.95498C3.3125 6.06922 3.40841 6.16183 3.52673 6.16183H4.07793V8.43612L4.07795 8.43862H3.95519C3.87405 8.43862 3.79987 8.48288 3.76358 8.55296L3.33512 9.38034C3.30191 9.44446 3.30546 9.52061 3.3445 9.58159C3.38353 9.64257 3.45248 9.67969 3.52673 9.67969H9.09671C9.17096 9.67969 9.23992 9.64257 9.27895 9.58159C9.31798 9.52061 9.32153 9.44446 9.28833 9.38034L8.85987 8.55296C8.82358 8.48288 8.7494 8.43862 8.66825 8.43862H8.57519L8.57522 8.43612V6.16183H9.09671C9.21503 6.16183 9.31094 6.06922 9.31094 5.95498V5.33445C9.31094 5.25835 9.26767 5.1884 9.19828 5.15233L6.41329 3.70441ZM7.93249 8.43612L7.93252 8.43862H7.29034C7.29035 8.43779 7.29036 8.43695 7.29036 8.43612V6.16183H7.93249V8.43612ZM6.64764 6.16183V8.43612C6.64764 8.43695 6.64765 8.43779 6.64766 8.43862H5.99997L5.99999 8.43612V6.16183H6.64764ZM5.35727 6.16183H4.72065V8.43612L4.72062 8.43862H5.35729L5.35727 8.43612V6.16183Z"
        fill="#9B9B9B"
      />
    </g>
    <rect
      x="0.8125"
      y="1.17969"
      width="11"
      height="11"
      rx="5.5"
      stroke="#9B9B9B"
    />
    <defs>
      <clipPath id="clip0_7300_55005">
        <rect
          x="0.3125"
          y="0.679688"
          width="12"
          height="12"
          rx="6"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
