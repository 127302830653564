import type { FC, PropsWithChildren } from 'react';
import { useWorkspace } from 'hooks/useWorkspace';
import React, { useCallback, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { putLocalStorage } from 'utils/localStorage';
import { useAuth0 } from '@auth0/auth0-react';

import { Icon } from '@fragment/ui/src/components/Icon';

export const IdleTimerContainer: FC<PropsWithChildren> = ({ children }) => {
  const [isIdle, setIsIdle] = useState(false);

  const onIdleHandler = useCallback(() => {
    setIsIdle(true);
    putLocalStorage('user_redirect_href', { path: window.location.href });
  }, []);

  useIdleTimer({
    timeout: 3 * 60 * 60 * 1000, // 3 hours
    onIdle: onIdleHandler,
    emitOnAllTabs: true,
    eventsThrottle: 1000,
  });

  const { logout } = useAuth0();

  const { workspace } = useWorkspace();

  return workspace && isIdle ? (
    // TODO: abstract out to a layout once more similar cases arise
    // TODO: use TextButton once that exists (?)
    <div className="ml-f4 mt-f4 flex flex-col gap-f8 items-start">
      <span className="text-fxl">Session timed out, login again.</span>
      <button
        type="button"
        className="text-fxl left-f0"
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/login`,
            },
          })
        }
      >
        Go To Login <Icon type="right" size="text-fxl" primaryColor="gray" />
      </button>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
