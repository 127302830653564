import React, { type FC, useCallback, useState } from 'react';

import { type FormButtonsProps } from '../Button/ButtonGroup/FormButtons';

type PageProps = {
  /**
   * Callback to be invoked on form submission of each individual
   * form page.
   */
  onPageSubmit: (formValues: Record<string, string>) => void;
  /**
   * The values of the form fields that have been filled out so far.
   * This isn't page-aware, so duplicate field names across pages will
   * cause collisions.
   */
  formValues: Record<string, string>;
  /**
   * Props provided by MultiPartForm to instantiate FormButtons within
   * each individual form page.
   */
  formButtonsProps: Omit<FormButtonsProps, 'onSubmit'>;
};

type Props = {
  pages: React.FunctionComponent<PageProps>[];
  onCancel: () => void;
  preventEventDefault?: boolean;
  onSubmit: (fieldValues: Record<string, string>) => void;
};

export const MultiPartForm: FC<Props> = ({
  pages,
  onCancel,
  onSubmit,
  preventEventDefault = false,
}) => {
  const [fieldValues, setFieldValues] = useState({});
  const [activePageNumber, setActivePageNumber] = useState(0);
  const ActivePage = pages[activePageNumber];

  const isFirstPage = activePageNumber === 0;
  const isLastPage = activePageNumber === pages.length - 1;

  const cancelButton: Pick<FormButtonsProps, 'cancelButtonLabel' | 'onCancel'> =
    isFirstPage
      ? {
          cancelButtonLabel: 'Cancel',
          onCancel: () => onCancel(),
        }
      : {
          cancelButtonLabel: 'Back',
          onCancel: () => setActivePageNumber((num) => num - 1),
        };
  const submitButton: Pick<FormButtonsProps, 'submitButtonLabel'> = isLastPage
    ? {
        submitButtonLabel: 'Create',
      }
    : {
        submitButtonLabel: 'Next',
      };

  const handlePageSubmit = useCallback(
    (formValues: Record<string, string>, e?: React.BaseSyntheticEvent) => {
      if (e && preventEventDefault) {
        e.preventDefault();
      }
      setFieldValues((oldFieldValues) => {
        const newValues = { ...oldFieldValues, ...formValues };
        if (activePageNumber === pages.length - 1) {
          onSubmit(newValues);
        } else {
          setActivePageNumber((num) => num + 1);
        }
        return newValues;
      });
    },
    [preventEventDefault, activePageNumber, pages.length, onSubmit]
  );

  return (
    <ActivePage
      onPageSubmit={handlePageSubmit}
      formValues={fieldValues}
      formButtonsProps={{
        type: 'tertiary',
        ...cancelButton,
        ...submitButton,
      }}
    />
  );
};
