import React from 'react';

import { useUserInfo } from '../../../../hooks/useUserInfo';
import { LogoutButton } from '../../../Profile/LogoutButton/LogoutButtonV2';

export const Profile = () => {
  const { data: userInfo } = useUserInfo();
  return (
    <div className="flex flex-col h-full">
      <span>Profile</span>
      <div className="flex space-x-[1ch] mt-f4">
        {userInfo?.image ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img className="h-f4" src={userInfo.image} alt="User profile" />
        ) : (
          <div className="flex items-center justify-center rounded-full h-f4 w-f4 bg-main-200">
            F
          </div>
        )}
        <div className="flex flex-col">
          <span>
            {userInfo?.firstName} {userInfo?.lastName}
          </span>
          <span className="text-main-500">{userInfo?.email}</span>
        </div>
      </div>
      <div className="mt-f2">
        <span className="text-main-500">
          Member of {userInfo?.workspaces?.length} Workspaces
        </span>
      </div>
      <div className="mt-auto w-fit">
        <LogoutButton />
      </div>
    </div>
  );
};
