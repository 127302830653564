import type { FC } from 'react';
import React from 'react';

import { ColorModeContext } from './ColorModeContext';

export const ColorModeSelector: FC = () => {
  const colorContext = React.useContext(ColorModeContext);
  const { mode, setMode } = colorContext;

  return (
    <div>
      <div
        className="group hover:cursor-pointer"
        onClick={() => setMode('light')}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? (
          <span className="text-main">●</span>
        ) : (
          <>
            {' '}
            <span className="group-hover:hidden text-main-500">○</span>
            <span className="hidden group-hover:inline text-main">●</span>
          </>
        )}

        <span> Light</span>
      </div>
      <div
        className="group hover:cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => setMode('dark')}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setMode('dark');
          }
        }}
      >
        {mode === 'dark' ? (
          <span className="text-main">●</span>
        ) : (
          <>
            {' '}
            <span className="group-hover:hidden text-main-500">○</span>
            <span className="hidden group-hover:inline text-main">●</span>
          </>
        )}
        <span> Dark</span>
      </div>
      <div
        className="group hover:cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => setMode('system')}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setMode('system');
          }
        }}
      >
        {mode === 'system' ? (
          <span className="text-main">●</span>
        ) : (
          <>
            {' '}
            <span className="group-hover:hidden text-main-500">○</span>
            <span className="hidden group-hover:inline text-main">●</span>
          </>
        )}
        <span> System</span>
      </div>
    </div>
  );
};
