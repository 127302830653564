import type { FC } from 'react';
import React from 'react';

import type { BaseSvgProps } from '../../utils';
import { colorToHexCode } from '../../utils';

export const LeftTriangle: FC<BaseSvgProps> = ({
  'data-testid': dataTestId,
  primaryColorHexCode = colorToHexCode.gray,
}) => (
  <svg
    data-testid={dataTestId}
    width="20"
    height="22"
    className="shrink-0"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.929687 10.7578L19.3164 0.142234L19.3164 21.3734L0.929687 10.7578Z"
      fill={primaryColorHexCode}
    />
  </svg>
);
