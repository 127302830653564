import type { CurrencyCode } from '@fragment/types';

import { CurrencyMode } from '../types/customerApi';

export type FormatCurrency = {
  code: CurrencyCode;
  customCode?: string | null | undefined;
};

export const formatAmountCurrency = (currency: FormatCurrency) =>
  currency.code === 'CUSTOM' ? currency.customCode : currency.code;

export const formatAccountCurrency = (
  currencyMode: CurrencyMode,
  currency?: FormatCurrency | null | undefined
) => {
  if (currencyMode === CurrencyMode.Multi) {
    return 'MULTI';
  }
  if (currency) {
    return formatAmountCurrency(currency);
  }
  throw new Error('currency required when currencyMode is single');
};
