import type { FC } from 'react';
import React from 'react';

import { type BaseSvgProps, colorToHexCode, sizeToSvgWidth } from '../../utils';

export const Schema: FC<BaseSvgProps> = ({
  width = sizeToSvgWidth.fnormal,
  'data-testid': testId,
}) => (
  <svg
    data-testid={testId}
    width={width}
    height={width}
    className="shrink-0"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      y="6"
      width="3.5"
      height="3.5"
      fill={colorToHexCode['expense-yellow']}
    />
    <rect
      x="6"
      y="2.5"
      width="3.5"
      height="3.5"
      fill={colorToHexCode['income-blue']}
    />
    <rect
      x="2.5"
      y="6"
      width="3.5"
      height="3.5"
      fill={colorToHexCode['liability-red']}
    />
    <rect
      x="2.5"
      y="2.5"
      width="3.5"
      height="3.5"
      fill={colorToHexCode['asset-green']}
    />
    <rect x="1" y="1" width="10" height="10" stroke="rgb(var(--colour-main))" />
  </svg>
);
