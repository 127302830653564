import React from 'react';

import { type BaseSvgProps, colorToHexCode } from '../../utils';

export const EntryGroup = ({
  width = 12,
  'data-testid': testId,
}: BaseSvgProps) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={testId}
    className="shrink-0"
  >
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2V6H2Z"
      fill={colorToHexCode['asset-green']}
    />
    <path
      d="M6 2C8.20914 2 10 3.79086 10 6H6V2Z"
      fill={colorToHexCode['income-blue']}
    />
    <path
      d="M2 6H6V10C3.79086 10 2 8.20914 2 6Z"
      fill={colorToHexCode['liability-red']}
    />
    <path
      d="M6 6H10C10 8.20914 8.20914 10 6 10V6Z"
      fill={colorToHexCode['expense-yellow']}
    />
    <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="#0D0D0D" />
  </svg>
);
