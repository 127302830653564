import { useCallback, useEffect, useState } from 'react';

// Taken from https://dev.to/brettfishy/the-easiest-way-to-use-query-parameters-in-react-1ioe

const getQueryParamsDict = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key: string): string | null =>
  getQueryParamsDict().get(key);

export const useQueryParam = <T extends string = string>(
  key: string,
  defaultVal?: T
): [T | undefined, (val: T | undefined) => void] => {
  const [query, setQuery] = useState<T | undefined>(
    (getQueryStringVal(key) as T) || defaultVal
  );

  const updateUrl = useCallback(
    (newVal: T | undefined) => {
      setQuery(newVal);

      const paramsDict = getQueryParamsDict();

      if (newVal && newVal.trim() !== '') {
        paramsDict.set(key, newVal);
      } else {
        paramsDict.delete(key);
      }

      // This check is necessary if using the hook with Gatsby
      if (typeof window !== 'undefined') {
        const queryString = new URLSearchParams(paramsDict).toString();
        const { protocol, pathname, host } = window.location;
        const newUrl = `${protocol}//${host}${pathname}${
          queryString && `?${queryString}`
        }`;
        window.history.pushState({}, '', newUrl);
      }
    },
    [key]
  );

  // Allows external changes to the querystring to update the state
  useEffect(() => {
    setQuery((getQueryStringVal(key) as T) || defaultVal);
  }, [window.location.search]);

  return [query, updateUrl];
};
