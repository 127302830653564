import { useEffect, useState } from 'react';

const frames = ['', '', '.', '..', '...', '...'];

export const useLoadingText = (value: string) => {
  const [animationFrame, setAnimationFrame] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(
      () => setAnimationFrame((oldValue) => (oldValue + 1) % 6),
      200
    );
    return () => clearInterval(interval);
  });

  const dots = frames[animationFrame];
  return `${value}${dots}`;
};
