import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import React from 'react';

import type { TWMaxHeightProp } from '../../types/tailwind';

export type ResponsiveOffsetProps<HeightProp> = {
  verticalOffset: TWMaxHeightProp<HeightProp>;
};

export const ResponsiveOffset = <HeightProp,>({
  verticalOffset,
  children,
}: PropsWithChildren<ResponsiveOffsetProps<HeightProp>>) => (
  <div className="flex flex-col h-full w-full">
    <div className={clsx('h-full w-full', verticalOffset)} />
    <div>{children}</div>
    <div className={clsx('h-full w-full', verticalOffset)} />
  </div>
);
