import clsx from 'clsx';
import React, { type FC, type PropsWithChildren } from 'react';

type PreTagProps = {
  hasTitle: boolean;
  textSize: string;
};

export const PreTag: FC<PropsWithChildren<PreTagProps>> = ({
  hasTitle,
  children,
  textSize,
}) => {
  const classes = clsx(
    [
      'codeblock-pre',
      'px-f2',
      'bg-negative',
      'whitespace-pre-wrap',
      'break-all',
      'overflow-auto',
      'scrollbar-hide',
      'w-full',
    ],
    hasTitle && 'pb-f2',
    !hasTitle && 'py-f2',
    textSize
  );
  return <pre className={classes}>{children}</pre>;
};
