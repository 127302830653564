import { DeleteForm } from 'components/DeleteForm/DeleteForm';
import { useToast } from 'components/ToastManager';
import { useDeleteWorkspaceMemberMutation as useDeleteWorkspaceMemberMutationGlobal } from 'hooks/globalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import { useUserInfo } from 'hooks/useUserInfo';
import { useWorkspaceId } from 'hooks/useWorkspace';
import React, { useCallback, useEffect } from 'react';
import { useCatchMutationError } from 'utils/urql';

import { Toast } from '@fragment/ui/src/components/Toast';

type Params = {
  row: { id: string; created: string; email: string };
  onDelete: () => void;
};

export const MemberAccessRevoker = ({ row, onDelete }: Params) => {
  const workspaceId = useWorkspaceId();
  const { context } = useGlobalApiContext();
  const [{ fetching, error }, deleteWorkspaceMember] = useCatchMutationError(
    useDeleteWorkspaceMemberMutationGlobal,
    'deleteMemberFromWorkspace'
  );

  const { showToast } = useToast();
  const { data: user } = useUserInfo();
  const submitRevokeMember = useCallback(async () => {
    const { error: resultError } = await deleteWorkspaceMember(
      {
        workspaceId,
        userId: row.id,
      },
      context
    );

    if (!resultError) {
      onDelete();
      showToast(<Toast type="success" message="Access Revoked" />);
    } else {
      showToast(
        <Toast type="error" message="Error revoking member, try again." />
      );
    }
  }, [
    context,
    deleteWorkspaceMember,
    onDelete,
    row.id,
    workspaceId,
    showToast,
  ]);

  useEffect(() => {
    if (error) {
      showToast(
        <Toast type="error" message="Error revoking member, try again." />
      );
    }
  }, [error, showToast]);
  return (
    <div className="space-y-f2">
      <div>{row.email}</div>
      <DeleteForm
        confirmButtonText="Revoke Access"
        confirmText={`Are you sure you want to revoke access for ${row.email}? This action
        cannot be undone.`}
        fetching={fetching}
        fetchingText="Revoking Member"
        buttonActionText="Revoke Access"
        onConfirm={submitRevokeMember}
        disabled={row.email === user?.email}
      />
    </div>
  );
};
